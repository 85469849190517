import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    TelegramNotificationsForm: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "85vh",
        justifyContent: "center",
        width: "100%",
    },
});