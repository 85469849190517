import * as React from "react";
import {LayoutProps} from "./LayoutProps";
import {observer} from "mobx-react-lite";
import useStyles from './LayoutStyle';
import {Header} from "../../components/Header";
import {MenuDrawer} from "../../components/MenuDrawer";
import {mergeClasses} from "@fluentui/react-components";
import {useStore} from "../../hooks";
import {Loader} from "../../components/Loader";

/**
 * Компонент шаблона.
 */
const Layout: React.FC<LayoutProps> = (props) => {
    const styles = useStyles();
    const store = useStore();
    const styleForContent = (props.anotherBackground) ? styles.grayBackground : styles.whiteBackground;
    const changeVisibility = () => {
        store.layoutStore.setMenuDrawerIsOpen(!store.layoutStore.isMenuDrawerIsOpen)
    }

    return (<div className={mergeClasses(styles.page_layout, props.className)}>
            <Loader/>
            <Header className={styles.page_layout__header} title={props.pageTittle} onHideMenuClick={changeVisibility}/>
            <div className={mergeClasses(styles.page_layout__body, props.bodyStyles)}>
                <MenuDrawer/>
                <div className={mergeClasses(styles.page_content, styleForContent)}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

/**
 * Название компонента, отображаемое при отладке.
 */
Layout.displayName = 'Layout';

export default observer(Layout);