import {RootService} from "./rootService";
import {AxiosService} from "./axios";
import {ApiResult, UpdateAccessTokenRequestDto, UserSignInRequestDto, UserSignInResponseDto} from "../models";

export class AuthService {
    
    /**
     * Корневой Web Api сервис.
     */
    private readonly rootService: RootService;

    /**
     * Сервис axios
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор.
     * @param root корневой Web Api сервис.
     */
    constructor(root: RootService) {
        this.rootService = root;
        this.axios = new AxiosService();
    }

    /**
     * Выход пользователя из аккаунта
     * @param body
     */
    public async logOut(body: UpdateAccessTokenRequestDto): Promise<void> {
        try {
            await this.axios.post("/auth/logout", body);
        } catch (error: any) {
            console.log(error);
        }
    }

    /**
     * Авторизация пользователя.
     * @param body тело запроса.
     */
    public async signIn(
        body: UserSignInRequestDto,
    ): Promise<ApiResult<UserSignInResponseDto>> {
        try {
            const response = await this.axios.post<UserSignInResponseDto>(
                '/auth/login',
                {
                    username: body.Username,
                    password: body.Password
                }
            );

            if ('accessToken' in response.data) {
                this.rootService.tokenService.setTokens(response.data.accessToken, response.data.refreshToken);
            }

            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}