import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    gridContainer: {
        display: "block",
        maxWidth: '100%',
        overflow: 'auto',       // Prevent content from overflowing outside the block
    },
    dataGrid: {
        minWidth: '1175px',
        overflow: 'auto',  
        maxWidth: '100%'
    },
    duplicate: {
        backgroundColor: "rgba(177,172,155,0.56)"
    },
    downloadedDuplicate: {
        backgroundColor: "rgba(177,172,155,0.56)",
        fontWeight: "normal"
    },
    downloaded: {
        fontWeight: "normal"
    },
    normalRow: {
        fontWeight: "bold"
    },
    summaryBadges: {
        display: "flow",
        gap: "0.2em",
    },
    badgeDuplicate: {
        backgroundColor: "black",
        boxShadow: "0.1em"
    },
    badgeSpacing: {
        margin: "0.3em",
    },
    controlContainer: {
        display: "grid",
        gridTemplateColumns: '1fr 1fr',
        columnGap: "1.5em",
        width: '100%',
        justifyItems: "space-between",
    },
    controlButtons: {
        display: "grid",
        gridTemplateColumns: "0.9fr 0.9fr .9fr .9fr",
        maxHeight: "3em",
        columnGap: "1em",
        margin: "0.3em",
    },
    controlPagination: {
        justifySelf: "end",
        maxHeight: "3em",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        columnGap: "1em",
        margin: "0.3em",
    },
});