import React from "react";
import {Button} from "@fluentui/react-components";
import {OpenRegular} from "@fluentui/react-icons";
import {LogOpenDetailsButtonProps} from "./LogOpenDetailsButtonProp";
import { useNavigate } from 'react-router-dom';

/**
 * Кнопка для открытия подробной информации о логе
 */
const LogOpenDetailsButton: React.FC<LogOpenDetailsButtonProps> = (props) => {
    const navigate = useNavigate();
    return (<Button aria-label="Open" appearance={"subtle"} icon={<OpenRegular/>} onClick={() => {
        navigate('/info?botid='+props.logId);
    }}/>);
}

/**
 * Имя отображаемое при отладке
 */
LogOpenDetailsButton.displayName = "LogOpenDetailsButton";

export default LogOpenDetailsButton;