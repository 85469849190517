import {ApiResult} from "../models";
import { PasswordDto, PasswordVm} from "../models/password";
import {AxiosService} from "./axios";

export class PasswordService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * получить пароли
     */
    async getPasswordFilters(searchVm: PasswordDto): Promise<ApiResult<PasswordVm>> {
        try {
            const resp = await this.axios.post<PasswordVm>("/passwords/filtering/", searchVm);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить пароль
     */
    async deletePassword(passid: number): Promise<ApiResult<PasswordVm>> {
        try {
            const resp = await this.axios.post<PasswordVm>("/passwords/delete/"+ passid);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }



}