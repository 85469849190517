import {ApiResult} from "../models";
import {AxiosService} from "./axios";
import UserSession from "../models/sessions/UserSession";

/**
 * Сервис работы с сессиями пользователя
 */
export class SessionsService {

    /**
     * Экземпляр сервиса axios
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить информацию о сессиях
     */
    async GetSessions(): Promise<ApiResult<UserSession[]>> {
        try {
            const response = await this.axios.get<UserSession[]>("/session/list");
            return Promise.resolve(response);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удалить все сессии кроме текущей
     */
    async TerminateAll(sessionId: string): Promise<void> {
        try {
            await this.axios.post("/session/terminate", {sessionId: sessionId});
            return Promise.resolve();
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}