import {AxiosService} from "./axios";
import {CookiesPageDto} from "../models/cookies";
import {CookieFilteringDto} from "../models/cookies";
import {COOKIE_LIST_URL} from "../shared/constUrl";

/**
 * Сервис работы с Cookies
 */
export class CookiesService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список Cookies
     */
    async getList(data?: CookieFilteringDto): Promise<CookiesPageDto> {
        try {
            if (data) {
                const params = new URLSearchParams({
                    botId: data.botId.toString(),
                    keyword: data.keyword || '',
                    browserName: data.browserName || '',
                    pageNumber: data.pageNumber.toString(), // Преобразуем число в строку
                    pageSize: data.pageSize.toString(),     //
                });
                const response = await this.axios.get(`${COOKIE_LIST_URL}?${params.toString()}`)
                return Promise.resolve(response.data as CookiesPageDto);
            } else {
                const response = await this.axios.get("/cookies")
                return Promise.resolve(response.data as CookiesPageDto);
            }
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Получить список браузеров доступных пользователю
     * @returns Список браузеров для фильтрации
     */
    async getOptions(): Promise<string[]> {
        try {
            const response = await this.axios.get("/cookies/get-options");
            return Promise.resolve(response.data as string[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}