import {makeStyles, tokens} from "@fluentui/react-components";

export default makeStyles({
    page_layout: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    page_layout__header: {
        position: 'fixed',
    },
    page_layout__body: {
        flexGrow: 1,
        marginTop: '42px',
        display: 'inherit',
        width: '100%',
        height: '100%',
        background: tokens.colorNeutralBackground4
    },
    page_content: {
        width: '83%',
        height: '100%',
        marginRight: '0.5em',
        marginTop: '0.5em',
        marginLeft: '0.6em',
        marginBottom: '10px',
    },
    whiteBackground: {
        borderRadius: tokens.borderRadiusLarge,
        boxShadow: tokens.shadow8,
        background: tokens.colorNeutralBackground1,
    },
    grayBackground: {
        borderRadius: tokens.borderRadiusLarge,
        background: tokens.colorNeutralBackground4,
    }
});