import React, {ChangeEvent} from "react";
import {
    Button,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Field,
    Textarea,
    TextareaOnChangeData,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import {Dismiss24Regular, EditFilled, EditRegular} from '@fluentui/react-icons';
import {LogNoteValueProps} from "./LogNoteValueProp";
import {useServices} from "../../../hooks";
import {LoadingButton} from "../../ButtonWithLoadingState";
import {LoadingState} from "../../../models";
import {defaultToastId} from "../../../shared/constString";
import {ErrorCode} from "../../../models/ErrorCode";
import {GetMessageByCode} from "../../../utils";

/**
 * Кнопка отображения заметки к логу
 * @constructor
 */
const LogNoteButton: React.FC<LogNoteValueProps> = (props) => {
    const services = useServices();
    const [noteValue, setNoteValue] = React.useState<string>(props.noteValue)
    const [saveBtnState, setSaveBtnState] = React.useState<LoadingState>("initial");
    const [clearBtnState, setClearBtnState] = React.useState<LoadingState>("initial");
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    /**
     * Выбор иконки
     */
    const chooseIcon = () => {
        if (props.noteId > 0) {
            return <EditFilled/>
        }
        return <EditRegular/>
    }

    /**
     * Отображение кнопки очистить и сохранить
     */
    const showDeleteNoteButton = () => {
        if (props.noteValue.length > 0) {
            return <LoadingButton state={clearBtnState}
                                  appearance={"secondary"}
                                  buttonText={"Delete"}
                                  onClick={onDeleteHandler}/>
        }
    }

    /**
     * ООбработчик кнопки "Clear & Save"
     */
    const onDeleteHandler = async () => {
        try {
            setClearBtnState("loading");
            await services.logNoteService.delete(props.noteId);
            setNoteValue('');
            setClearBtnState("loaded");
        } catch (error: any) {
            const message = error.code || ErrorCode.UnexpectedError;
            dispatchToast(
                <Toast>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{GetMessageByCode(message)}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
            setClearBtnState("error");
        }
    }

    /**
     * Обработчик кнопки "Save" для заметки
     */
    const onSaveHandler = async () => {
        try {
            setSaveBtnState("loading");
            await services.logNoteService.create({
                logId: props.logId,
                text: noteValue
            });
            setSaveBtnState("loaded");
        } catch (error: any) {
            const message = error.code || ErrorCode.UnexpectedError;
            dispatchToast(
                <Toast>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{GetMessageByCode(message)}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
            setSaveBtnState("error");
        }
    }

    /**
     * Обработчик изменения текстовой области
     * @param ev Событие
     * @param data Значение
     */
    const onChangeHandler = (ev: ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        if (noteValue !== data.value) {
            setNoteValue(data.value);
        }
    }

    return (<>
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Button aria-label="Edit" appearance={"subtle"} icon={chooseIcon()}/>
            </DialogTrigger>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular/>}
                                />
                            </DialogTrigger>
                        }
                    >
                        Log Note
                    </DialogTitle>
                    <DialogContent>
                        <Field>
                            <Textarea onChange={onChangeHandler}>{noteValue}</Textarea>
                        </Field>
                        <div style={{marginTop: "0.5em"}}>
                            <LoadingButton state={saveBtnState} appearance={"primary"}
                                           style={{margin: "0.3em"}}
                                           onClick={onSaveHandler} buttonText={"Save"}/>
                            {showDeleteNoteButton()}
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
        <Toaster toasterId={toasterId}/>
    </>);
}

/**
 * Имя отображаемое при отладке
 */
LogNoteButton.displayName = "LogNoteButton";

export default LogNoteButton;