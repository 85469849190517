import {makeStyles, tokens} from "@fluentui/react-components";

export default makeStyles({
    header: {
        backgroundColor: tokens.colorNeutralBackground4,
        maxHeight: '55px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    drawerButton: {
        textAlign: "center",
        padding: "10px 10px 10px 15px",
        fontSize: '26px',
        display: 'flex',
        flexDirection: 'column',
    },
    user: {
        alignItems: "flex-end",
        display: 'flex',
        flexDirection: 'column',
        paddingRight: tokens.spacingHorizontalL,
        margin:'0.3em'
    },
    headerTitle: {
        fontWeight: 'bolder',
        textAlign: 'center',
        minWidth: 0,
    },
    page_layout_header__container: {
        width: '100%',
        maxHeight: '70px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: 0,
        boxSizing: 'border-box',
        zIndex: 999,
    }
});