import * as React from "react";
import {ChannelArrowLeftRegular, ChannelRegular} from "@fluentui/react-icons";
import {useStore} from "../../hooks";
import useStyles from "./HeaderStyles";
import {observer} from "mobx-react-lite";
import {mergeClasses, Text} from "@fluentui/react-components";
import {UserHeaderButton} from "../UserHeaderButton";
import {HeaderProps} from "./HeaderProps";

/**
 * Заголовок страницы с элементами управления
 * @param props Параметры заголовка страницы
 * @constructor
 */
const Header: React.FC<HeaderProps> = (props) => {
    const store = useStore();
    const styles = useStyles();
    return (<div className={mergeClasses(styles.page_layout_header__container, props.className)}>
            <div className={styles.header}>
                <div>
                    {(store.layoutStore.isMenuDrawerIsOpen)
                        ? <ChannelArrowLeftRegular className={styles.drawerButton} onClick={props.onHideMenuClick}/>
                        : <ChannelRegular className={styles.drawerButton} onClick={props.onHideMenuClick}/>}
                </div>
                <Text font={"monospace"} size={400}>{props.title}</Text>
                <div className={styles.user}>
                    <UserHeaderButton/>
                </div>
            </div>
        </div>
    );
}

/**
 * Название компонента, отображаемое при отладке.
 */
Header.displayName = 'Header';

export default observer(Header);