/**
 * Маска для представления даты в виде строки в формате ISO 8601.
 * https://ru.wikipedia.org/wiki/ISO_8601
 */
const ISO_DATE_FORMAT_PATTERN = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z?$/;

/**
 * Проверить является ли строка представлением даты в формате ISO 8601.
 * @param value строка содержащая дату в формате ISO 8601.
 * @returns признак действительно ли строка является представление даты в формате ISO 8601.
 */
const isIsoDateFormatString = (value: string): boolean => {
    if (!value) {
        return false;
    }

    return ISO_DATE_FORMAT_PATTERN.test(value);
};

/**
 * Рекурсивный парсер строковых представлений дат в формате ISO 8601.
 * @param data произвольное значение.
 */
export const parseISODatesRecursively = (data: any): void => {
    if (data === null || data === undefined || typeof data !== 'object') {
        return;
    }

    for (const key of Object.keys(data)) {
        const value = data[key];

        if (typeof value === 'string' && isIsoDateFormatString(value)) {
            data[key] = new Date(value);
        } else if (typeof value === 'object') {
            parseISODatesRecursively(value);
        }
    }
};

export const dateToMskString = (date: Date): string => {
    if (!date) {
        return "";
    }

    date.setHours(date.getHours() + 3); // Добавляем 3 часа к UTC
    return date.toLocaleString("ru-RU", {timeZone: "Europe/Moscow"});
};



/**
 * Объединяет дату и время в один объект ДатаВремя
 * @param date Дата
 * @param time Время
 */
export const combineDateTime = (date: Date | null, time: string | null) => {
    if (date && time) {
        const [hours, minutes] = time.split(":").map(Number);
        const combined = new Date(date);
        combined.setHours(hours);
        combined.setMinutes(minutes);
        return combined;
    }
};
