import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    StatisticPageStyle: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
    },
});