import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Option,
    Select,
    Spinner,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text
} from '@fluentui/react-components';
import {DatePicker} from '@fluentui/react-datepicker-compat';
import {useServices} from '../../hooks';
import {BCountByCounty, StatisticViewModel, StatsQueryDto} from '../../models/statistic';
import UseStyle from './StatisticFormStyles';
import {Doughnut} from 'react-chartjs-2';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

/**
 * форма отображения статистики
 */

const StatisticForm: React.FC = () => {
    const [statisticData, setStatisticData] = useState<StatisticViewModel | null>(null);
    const [countries, setCountries] = useState<BCountByCounty[]>([]);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState<Date>(new Date('2023'));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [partnerId, setPartnerId] = useState<string>('');
    const [partnersOptions, setPartnersOptions] = useState<{ key: string; text: string }[]>([]);
    const [showAllCountries, setShowAllCountries] = useState(false);
    const services = useServices();
    const styles = UseStyle();

    /**
     * получение статистики с апи
     */
    const fetchData = async () => {
        setLoading(true);
        const body: StatsQueryDto = {
            startDate: startDate,
            endDate: endDate,
            partnerId: partnerId
        };

        try {
            const [statisticResult] = await Promise.all([
                services.statisticService.getStatisticData(body),
            ]);

            if (statisticResult.error) throw new Error(statisticResult.error.message);
            setStatisticData(statisticResult.data);

            if (showAllCountries) {
                const allCountriesResult = await services.statisticService.getAllCountriesSorted(body);
                if (allCountriesResult.error) throw new Error(allCountriesResult.error.message);
                setCountries(allCountriesResult.data);
            } else {
                const topCountriesResult = await services.statisticService.getTopThreeCountries(body);
                if (topCountriesResult.error) throw new Error(topCountriesResult.error.message);
                setCountries(topCountriesResult.data);
            }
        } catch (error: any) {
            console.error('Error fetching statistics:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [startDate, endDate, partnerId, showAllCountries]);

    const chartData = {
        labels: ['Empty', 'Total', 'Important'],
        datasets: [
            {
                label: 'Statistics',
                data: statisticData ? [statisticData.emptyCount, statisticData.totalCount, statisticData.importantCount] : [0, 0, 0],
                backgroundColor: ['#0078d4', '#00bcf2', '#ff8c00'],
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        maintainAspectRatio: false,
    };

    return (
        <Card className={styles.cardContainer}>
            <div>
                <DatePicker
                    value={startDate}
                    onSelectDate={(date) => setStartDate(date || new Date(0))}
                />
                <DatePicker
                    value={endDate}
                    onSelectDate={(date) => setEndDate(date || new Date())}
                />
                <Select
                    value={partnerId}
                    onChange={(event) => setPartnerId(event.target.value)}
                >
                    {partnersOptions.map((partner) => (
                        <Option key={partner.key} value={partner.key}>
                            {partner.text}
                        </Option>
                    ))}
                </Select>
                <Button onClick={fetchData}>Search</Button>
            </div>
            {loading ? (
                <Spinner/>
            ) : (
                <>
                    {statisticData && (
                        <div className={styles.statisticContainer}>
                            <Text size={500}>General Statistics</Text>
                            <div className={styles.diagram}>
                                <Doughnut data={chartData} options={chartOptions}/>
                            </div>
                        </div>
                    )}
                    <div className={styles.countriesContainer}>
                        <Text size={500}>Top Countries</Text>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableCell>Country</TableCell>
                                    <TableCell>Number of Bots</TableCell>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {countries.length > 0 ? (
                                    countries.map((country, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{country.country}</TableCell>
                                            <TableCell>{country.count}</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2}>No Data</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        {!showAllCountries ? (
                            <Button onClick={() => setShowAllCountries(true)}>Show All</Button>
                        ) : (
                            <Button onClick={() => setShowAllCountries(false)}>Show Top 3</Button>
                        )}
                    </div>
                </>
            )}
        </Card>
    );
};

export default StatisticForm;
