import React from "react";
import {
    Field,
    Tag,
    TagPicker,
    TagPickerControl,
    TagPickerGroup,
    TagPickerInput,
    TagPickerList,
    TagPickerOption,
    TagPickerProps
} from "@fluentui/react-components";

import {TagPickerProp} from "./TagPickerProp";
import {observer} from "mobx-react-lite";

/**
 * Компонент выбора нескольких значений в Select
 * @constructor
 */
export const MultipleTagPicker: React.FC<TagPickerProp> = (props) => {
    const [selectedOptions, setSelectedOptions] = React.useState<string[]>([]);

    /**
     * Установление вариантов выбора
     */
    const tagPickerOptions = props.options.filter(
        (option) => !selectedOptions.includes(option)
    );

    /**
     * Обработчик изменения выбора
     * @param _e
     * @param data
     */
    const onOptionSelect: TagPickerProps["onOptionSelect"] = (_e, data) => {
        setSelectedOptions(data.selectedOptions);
        if (props.onChange) {
            props.onChange(data.selectedOptions);
        }
    };

    return (<Field label={props.labelText} style={{maxWidth: 400}} size={"small"}>
        <TagPicker
            onOptionSelect={onOptionSelect}
            selectedOptions={selectedOptions}
        >
            <TagPickerControl>
                <TagPickerGroup>
                    {selectedOptions.map((option) => (
                        <Tag size={"small"}
                             key={option}
                             shape="rounded"
                             value={option}
                        >
                            {option}
                        </Tag>
                    ))}
                </TagPickerGroup>
                <TagPickerInput/>
            </TagPickerControl>
            <TagPickerList>
                {tagPickerOptions.length > 0
                    ? tagPickerOptions.map((option) => (
                        <TagPickerOption
                            value={option}
                            key={option}
                        >
                            {option}
                        </TagPickerOption>
                    ))
                    : "No options available"}
            </TagPickerList>
        </TagPicker>
    </Field>);
}

MultipleTagPicker.displayName = "MultipleTagPicker";

export default observer(MultipleTagPicker);