export class TokenService {

    /**
     * Маркер доступа.
     */
    public get accessToken(): string {
        return localStorage.getItem('access_token') || '';
    }

    public set accessToken(value: string) {
        localStorage.setItem('access_token', value);
    }

    /**
     * Маркер обновления.
     */
    public get refreshToken(): string {
        return localStorage.getItem('refresh_token') || '';
    }

    public set refreshToken(value: string) {
        localStorage.setItem('refresh_token', value);
    }

    /**
     * Установить значения маркеров доступа и обновления.
     * @param accessToken маркер доступа.
     * @param refreshToken маркер обновления.
     */
    public setTokens(accessToken: string, refreshToken: string) {
        localStorage.setItem('access_token', accessToken);
        localStorage.setItem('refresh_token', refreshToken);
    }

    /**
     * Удалить маркеры доступа и обновления из локального хранилища.
     */
    public removeTokens() {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
    }

    /**
     * Расшифровать токен доступа.
     */
    public decodeAccessToken(accessToken: string) {

        if (!accessToken)
            return "";

        const base64Url = accessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(''),
        );

        return JSON.parse(jsonPayload);
    }
}
