import Log from "../models/logs/Log";
import {makeAutoObservable} from "mobx";
import {LogFilteringOptions} from "../models/logs/LogFilteringOptions";
import {LogsRequestDto} from "../models/logs/LogRequestDto";
import {Paging} from "../models/common";
import {LogPageDto} from "../models/logs/LogPageDto";

/**
 * Хранилище списка логов
 */
export class LogsStore {
    /**
     * Конструктор хранилища логов пользователя
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Логи
     * @private
     */
    private _logs: Log[] = [];

    /**
     * Получить список логов
     */
    public get logs() {
        return this._logs;
    }

    /**
     * Удалить элемент списка
     * @param logId Идентификатор лога
     */
    removeItemById(logId: number) {
        this._logs = this._logs.filter(item => item.id !== logId);
    }

    /**
     * Сохранить список логов
     * @param value
     */
    public set logs(value: Log[]) {
        this._logs = value;
    }

    /**
     * Опции для поиска
     * @private
     */
    private _options: LogFilteringOptions = {
        countryOptions: [],
        tagOptions: [],
        extWalletNames: [],
        pmExtNames: [],
        walletNames: [],
        referralUsers: [],
        buildTags: []
    };

    /**
     * Получить список опций
     */
    public get options() {
        return this._options;
    }

    /**
     * Сохранить список опций
     * * @param value
     */
    public set options(value: LogFilteringOptions) {
        this._options = value;
    }

    /**
     * Параметры для запроса логов
     * @private
     */
    private _params: LogsRequestDto = {
        pageNumber: 1,
        pageSize: 50,
    }

    /**
     * Получить параметры
     */
    public get params() {
        return this._params;
    }

    /**
     * Сохранить параметры фильтрации
     * * @param value
     */
    public set params(value: LogsRequestDto) {
        this._params = value;
    }

    /**
     * Состояние пагинации на странице логов
     * @private
     */
    private _paging: Paging = {
        pageNumber: 1,
        pageSize: 50,
        totalItems: 0,
        totalPages: 1
    };

    /**
     * Получить параметры пагинации
     */
    public get paging() {
        return this._paging;
    }

    /**
     * Сохранить параметры пагинации
     * * @param value
     */
    public set paging(value: Paging) {
        this._paging = value;
    }

    /**
     * ООбновление переменных новыми данными после запроса
     * @param model
     */
    public updateData(model: LogPageDto) {
        this._logs = model.logList;
        this._paging = model.paging;
        this._params = {
            ...this._params,
            pageNumber: (model.paging.pageNumber === 0) ? 1 : model.paging.pageNumber,
            pageSize: model.paging.pageSize
        };
    }
}