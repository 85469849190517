import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    cardContainer: {
        minWidth: "400px",
        maxWidth: "500px",
        height: "fit-content",
    },
    form: {
        display: "flex",
        flexDirection: "column",
        gap: "20px",
        // Stack the label above the field (with 2px gap per the design system)
        "> div": {display: "flex", flexDirection: "column", gap: "2px"},
    },
});