import {MailInbox28Regular} from "@fluentui/react-icons";
import {Text} from "@fluentui/react-components";
import React from "react";
import useStyles from "./NoItemsStyle";

/**
 * Компонент для отображения сообщения об отсутствии элементов
 * @constructor
 */
const NoItems: React.FC = () => {
    const styles = useStyles();

    return (<div className={styles.noItems}>
        <MailInbox28Regular/>
        <Text>No items</Text>
    </div>);
}

/**
 * Имя отображаемое при отладке
 */
NoItems.displayName = "NoItems";

export default NoItems