import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Toast,
    ToastBody,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import UseStyle from './TelegramNotificationsFormStyles';
import {useServices} from "../../hooks";
import {TelegramBotLinkOptionDto, TelegramNotificationsUsersDto} from "../../models/telegramNotifications";
import {GetMessageByCode} from "../../utils";
import {store} from "../../store";

/**
 * Форма пользователей Телеграм бота и их настройки
 * @constructor
 */
const TelegramNotificationsUsersForm: React.FC = () => {
    const styles = UseStyle();
    const services = useServices();
    const toasterId = useId();
    const {dispatchToast} = useToastController(toasterId);

    const [users, setUsers] = useState<TelegramNotificationsUsersDto[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<TelegramNotificationsUsersDto | null>(null);
    const [isDownloadLinksEnabled, setIsDownloadLinksEnabled] = useState(false);
    const [isNotificationOnlyWallet, setIsNotificationOnlyWallet] = useState(false);

    /**
     * Функция для отображения тостов с ошибками
     * @param error
     */
    const showErrorToast = useCallback(
        (error: any) => {
            dispatchToast(
                <Toast style={{ minHeight: "50px", minWidth: "200px" }}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
                </Toast>,
                { intent: "error" }
            );
        },
        [dispatchToast] // Добавляем зависимости
    );

    /**
     * Получение списка пользователей с API
     */
    const fetchUsers = useCallback(async () => {
        try {
            store.loaderStore.setLoading(true);
            const resApiUsers = await services.telegramBotService.getTelegramUser();
            setUsers(resApiUsers.data);
        } catch (error: any) {
            showErrorToast(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, [services.telegramBotService, showErrorToast]);

    /**
     * Инициализация данных и установка интервала для обновления списка пользователей
     */
    useEffect(() => {
        fetchUsers();
        const intervalId = setInterval(fetchUsers, 5000);
        return () => clearInterval(intervalId);
    }, [services.telegramBotService, fetchUsers]);

    /**
     * Функция включения/выключения уведомлений для пользователя
     * @param userId Идентификатор пользователя
     * @param newChecked Статус нотификации
     */
    const handleEnableNotification = async (userId: number, newChecked: boolean) => {
        const updatedUsers = users.map(user =>
            user.userId === userId ? {...user, isEnabled: newChecked} : user
        );

        setUsers(updatedUsers);

        const updatedUser = updatedUsers.find(user => user.userId === userId);
        if (updatedUser) {
            try {
                await services.telegramBotService.enableNotification(updatedUser);
            } catch (error: any) {
                showErrorToast(error);
            }
        }
    };

    /**
     * Событие кнопки "настройки" для пользователя
     * @param user Телеграм пользователь
     */
    const handleSettingClick = async (user: TelegramNotificationsUsersDto) => {
        try {
            setSelectedUser(user);
            const optionsState = await services.telegramBotService.getOptions(user.userId);
            setIsDownloadLinksEnabled(optionsState.data.isDownloadLinkEnabled);
            setIsNotificationOnlyWallet(optionsState.data.onlyWallets);
        } catch (error: any) {
            showErrorToast(error);
        }
        setIsDialogOpen(true);
    };

    /**
     * Событие кнопки "Удалить" пользователя телеграм
     * @param userId
     */
    const handleDeleteClick = async (userId: number) => {
        try {
            store.loaderStore.setLoading(true);
            await services.telegramBotService.deleteTelegramUser(userId);
            await fetchUsers();
        } catch (error: any) {
            showErrorToast(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    /**
     *  Закрытие диалога
     */
    const handleDialogClose = () => {
        setIsDialogOpen(false);
        setSelectedUser(null);
    };

    /**
     * Событие чекбокса с включением ссылки на скачивание
     * @param newChecked
     */
    const handleDownloadLinksChange = async (newChecked: boolean) => {
        try {
            if (!selectedUser) return;

            const linkOptionDto: TelegramBotLinkOptionDto = {
                Password: '123',
                CurrentDomain: 'https://' + window.location.hostname,
                TelegramUserId: selectedUser.userId
            };

            store.loaderStore.setLoading(true);

            if (newChecked) {
                await services.telegramBotService.enableOptionsLink(linkOptionDto);
            } else {
                await services.telegramBotService.disableOptionsLink(linkOptionDto);
            }
            setIsDownloadLinksEnabled(newChecked);
        } catch (error: any) {
            showErrorToast(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    /**
     *  Событие чекбокса с включением оповещения только логов с кошельками
     * @param checked
     */
    const handleOnOnlyWalletsClick = async (checked: boolean) => {
        try {
            if (selectedUser?.userId) {
                await services.telegramBotService.changeOnlyWallets(selectedUser?.userId, checked);
                setIsNotificationOnlyWallet(checked);
            }
        } catch (error: any) {
            showErrorToast(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    }

    return (
        <div className={styles.TelegramNotificationsForm}>
            <div className={styles.cardContainer}>
                <div className={styles.header}>
                    <h2>Notification settings</h2>
                </div>
                <Table className={styles.table}>
                    <TableHeader>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>User name</TableCell>
                            <TableCell>Configure notification</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {users.map((user, index) => (
                            <TableRow key={user.userId}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{user.username}</TableCell>
                                <TableCell>
                                    <Checkbox
                                        label="Enable notification"
                                        checked={user.isEnabled}
                                        onChange={(e, _data) => handleEnableNotification(user.userId, e.target.checked)}
                                        disabled={store.loaderStore.loading}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button
                                        size="medium"
                                        appearance="primary"
                                        onClick={() => handleSettingClick(user)}
                                        disabled={store.loaderStore.loading} // Блокировка кнопки при загрузке
                                    >
                                        Settings
                                    </Button>
                                    <Button
                                        className={styles.deleteButton}
                                        size="medium"
                                        appearance="primary"
                                        onClick={() => handleDeleteClick(user.userId)}
                                        disabled={store.loaderStore.loading} // Блокировка кнопки при загрузке
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {isDialogOpen && selectedUser && (
                    <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
                        <DialogSurface>
                            <DialogBody>
                                <DialogTitle>Settings</DialogTitle>
                                <DialogContent>
                                    <Checkbox
                                        label="Enabling download links"
                                        checked={isDownloadLinksEnabled}
                                        onChange={(e) => handleDownloadLinksChange(e.target.checked)}
                                        disabled={store.loaderStore.loading} // Блокировка чекбокса при загрузке
                                    />
                                    <Checkbox
                                        label="Only with wallet"
                                        checked={isNotificationOnlyWallet}
                                        onChange={(e) => handleOnOnlyWalletsClick(e.target.checked)}
                                        disabled={store.loaderStore.loading} // Блокировка чекбокса при загрузке
                                    />
                                </DialogContent>
                            </DialogBody>
                        </DialogSurface>
                    </Dialog>
                )}
            </div>
        </div>
    );
};

TelegramNotificationsUsersForm.displayName = "TelegramNotificationsUsersForm";
export default TelegramNotificationsUsersForm;
