import {CookiesRestoreDto} from "../../models/cookiesRestore";

/**
 * Правила валидации данных форма "восстановление кукисов"
 * @param values
 */
export const validate = (values: CookiesRestoreDto) => {

    const errors = {
        restoreKey: '',
        proxyHost: '',
        socksUser: '',
        socksPassword: ''
    };

    
    if (!values.restoreKey) {
        errors.restoreKey = "Required.";
    }
    
    if (!/^.*\/\/.*:.*$/.test(values.restoreKey)) {
        errors.restoreKey = "Required.";
    }

    if (!values.proxyHost) {
        errors.proxyHost = "Required.";
    }

    const hostPattern = /^(([a-zA-Z\d]([a-zA-Z\d-]{0,61}[a-zA-Z\d])?)\.)+[a-zA-Z]{2,}|((\d{1,3}\.){3}\d{1,3})$/;
    if (!hostPattern.test(values.proxyHost)) {
        errors.proxyHost = "Invalid host.";
    }

    if (errors.restoreKey || errors.proxyHost) {
        return errors;
    }
}