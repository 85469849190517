import { TableColumnSizingOptions } from "@fluentui/react-components";

/**
 * Настройки размеров столбцов
 */
export const columnSizingOptions: TableColumnSizingOptions = {
  'browserName': {
    minWidth: 100,
    defaultWidth: 100,
    idealWidth: 150
  },
  'dataString': {
    minWidth: 200,
    defaultWidth: 400,
    idealWidth: 400
  },
 'actions': {
    minWidth: 115,
    defaultWidth: 120,
    idealWidth: 120
  },
};
