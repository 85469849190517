import {ErrorCode} from "../models/ErrorCode";

/**
 * Получение сообщения об ошибке по коду
 * @param code Код ошибки
 * @constructor
 */
export const GetMessageByCode = (code: number) => {
    switch (code) {
        case ErrorCode.IncorrectLoginOrPassword:
            return "Incorrect Login or Password.";
        case ErrorCode.SubscriptionInfoUpdateFailed:
            return "Subscription Info Update Failed.";
        case  ErrorCode.UnableGetData:
            return "Unable to get data.";
        case  ErrorCode.PasswordMismatch:
            return "Password mismatch.";
        case ErrorCode.PasswordNotEqual:
            return "Password not equal.";
        case ErrorCode.PasswordRequiresNonAlphanumeric:
            return "Passwords must have at least one non alphanumeric character.";
        case ErrorCode.PasswordRequiresLower:
            return "Passwords must have at least one lowercase ('a'-'z').";
        case ErrorCode.PasswordRequiresUpper:
            return "Passwords must have at least one uppercase ('A'-'Z')";
        case ErrorCode.PasswordToShort:
            return "Password too short";
        case ErrorCode.BuilderIsUnavailable:
            return "Builder is Unavailable.";
        case ErrorCode.TooLargeFile:
            return "File too large";
        case  ErrorCode.UserDoesNotExist:
            return "User doesn't exist.";
        case ErrorCode.AddAtToUsername:
            return "Add @ to username"
        case ErrorCode.InternalServerError:
            return "Internal server error.";
        default:
            return "Unexpected Error";
    }
}