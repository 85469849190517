import {RootService} from "./rootService";
import {AxiosService} from "./axios";

/**
 * Сервис для создания запросов на генерацию файлов для скачивания
 */
export class FileDownloadService {
    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Корневой сервис
     * @private
     */
    private rootService: RootService;

    /**
     *   Конструктор
     * @param root Корневой сервис
     */
    constructor(root: RootService) {
        this.rootService = root;
        this.axios = new AxiosService();
    }

    /**
     * Запросить все файлы для одного лога
     */
    async requestLogFiles(logId: number): Promise<void> {
        try {
            await this.axios.get(`/api/file/bot?id=${logId}`);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для нескольких логов
     */
    async requestLogsFiles(logId: number[]): Promise<void> {
        try {
            await this.axios.post('api/file/multiple', logId);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Запросить все файлы для всех логов пользователя
     */
    async requestAllLogFiles(): Promise<void> {
        try {
            await this.axios.get('api/file/all');
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Скачивание файла
     * @param targetUrl адрес файла
     */
    async downloadFile(targetUrl: string): Promise<void> {
        try {
            await this.axios.downloadFile(targetUrl);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}