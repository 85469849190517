import { ApiResult } from "../models";
import { BotInfo } from "../models/botInfo";
import { AxiosService } from "./axios";

export class BotInfoService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;


    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * запрос информации о боте
     */
    async getInfoBot(body: string): Promise<ApiResult<BotInfo>> {
        try {
            const resp = await this.axios.get<BotInfo>("log/details?botid="+ body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}