import {makeAutoObservable} from "mobx";
import {services} from "../services";
import {UserRole} from "../models/account";

export class UserStore {

    /**
     * Признак авторизации пользователя.
     */
    private _isAuthorized: boolean = !!localStorage.getItem('access_token');

    constructor() {
        makeAutoObservable(this);
    }

    setAuthorization(value: boolean) {
        this.isAuthorized = value;
    }

    /**
     * Признак авторизации пользователя.
     */
    public get isAuthorized() {
        return this._isAuthorized;
    }

    public set isAuthorized(value: boolean) {
        this._isAuthorized = value;
    }

    public get userName(): string {
        const obj = services.tokenService.decodeAccessToken(services.tokenService.accessToken);
        return obj['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    }

    /**
     * Признак, является ли пользователь админом.
     */
    public get isAdmin() {
        const obj = services.tokenService.decodeAccessToken(services.tokenService.accessToken);
        const roleOrRoles = obj['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        const roles = [roleOrRoles].flat();
        return roles ? roles.some(el => el === UserRole.AdminRole) : false;
    }

    /**
     * Получить идентификатор пользователя
     * @constructor
     */
    public get userId(): string {
        const obj = services.tokenService.decodeAccessToken(services.tokenService.accessToken);
        return obj.sub
    }
}