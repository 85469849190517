import React, {useCallback} from "react";
import {Card, Field, Input, Switch} from "@fluentui/react-components";
import {DatePicker} from "@fluentui/react-datepicker-compat";
import {TimePicker,} from "@fluentui/react-timepicker-compat";
import useStyle from './LogFiltersStyle';
import MultipleTagPicker from "../../TagPicker/TagPicker";
import {useServices, useStore} from "../../../hooks";
import LoadingButton from "../../ButtonWithLoadingState/LoadingButton";
import {LoadingState} from "../../../models";

/**
 * Компонент фильтрации логов
 * @constructor
 */
const LogFilters: React.FC = () => {
    const style = useStyle();
    const store = useStore();
    const services = useServices();
    const [loadingState, setLoadingState] = React.useState<LoadingState>("initial");
    const [startDate, setStartDate] = React.useState<Date | undefined>();

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = useCallback(async () => {
        try {
            store.loaderStore.setLoading(true);
            setLoadingState("loading");
            const response = await services.logsService.getLogsList(store.logsStore.params)
            store.logsStore.updateData(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        } finally {
            store.loaderStore.setLoading(false);
        }
    }, []);

    /**
     * Обработчик изменения стартовой даты
     * @param date
     */
    const handleStartDateChange = (date: Date | undefined | null) => {
        store.logsStore.params.startDate = date;
    };

    /**
     * Обработчик изменения конечной даты
     * @param date
     */
    const handleEndDateChange = (date: Date | undefined | null) => {
        store.logsStore.params.endDate = date;
    };

    return (
        <Card className={style.filterContainer}>
            <div className={style.dateTimeContainer}>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a start date">
                        <DatePicker onSelectDate={handleStartDateChange} placeholder="Select a start date..."/>
                    </Field>
                    <Field label="Select a start time">
                        <TimePicker disabled={true} hourCycle={"h24"}
                                    placeholder="Select a start time..."/>
                    </Field>
                </div>
                <div className={style.dateTimeItemContainer}>
                    <Field label="Select a end date">
                        <DatePicker onSelectDate={handleEndDateChange} placeholder="Select a end date..."/>
                    </Field>
                    <Field label="Select a end time">
                        <TimePicker disabled={true} hourCycle={"h24"} placeholder="Select a end time..."/>
                    </Field>
                </div>
                <Field label="Log ID">
                    <div className={style.logRangeSelectContainer}>
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.startId = Number(data.value);
                        }} min={0} type={"number"} placeholder={'Start ID'}></Input>
                        <Input onChange={(_ev, data) => {
                            store.logsStore.params.endId = Number(data.value);
                        }} type={"number"} placeholder={'End ID'}></Input>
                    </div>
                </Field>
                <Field label="IP">
                    <Input type={"text"} placeholder={"IP"} onChange={(_ev, data) => {
                        store.logsStore.params.ipAddress = data.value;
                    }}></Input>
                </Field>
                <Field label="Cookie keyword">
                    <Input onChange={(_ev, data) => {
                        store.logsStore.params.cookieKeyword = data.value;
                    }} type={"text"}></Input>
                </Field>
                <Field label="Password keyword">
                    <Input type={"text"}
                           onChange={(_ev, data) => {
                               store.logsStore.params.passwordKeyword = data.value;
                           }}></Input>
                </Field>
            </div>
            <div className={style.tagsSelectorsContainer}>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.tagsList = values;
                }}
                                   options={store.logsStore.options.tagOptions} labelText={"By Tag"}/>
                <MultipleTagPicker
                    onChange={(values: string[]) => {
                        store.logsStore.params.countryList = values;
                    }}
                    options={store.logsStore.options.countryOptions} labelText={"By Country"}/>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.walletsList = values;
                }} options={store.logsStore.options.walletNames} labelText={"By Wallet"}/>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.walletExtensionsList = values;
                }} options={store.logsStore.options.extWalletNames} labelText={"By ext-Wallet"}/>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.pm2FaExtensionsList = values;
                }} options={store.logsStore.options.pmExtNames} labelText={"By PM/2Fa ext"}/>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.buildTagList = values;
                }} options={store.logsStore.options.buildTags} labelText={"By build"}/>
                <MultipleTagPicker onChange={(values: string[]) => {
                    store.logsStore.params.partnerList = values;
                }} options={store.logsStore.options.referralUsers.map(x => x.username)}
                                   labelText={"By partner"}/>

            </div>
            <div className={style.switchesContainer}>
                <Field label={"Required"}>
                    <Switch label={"Wallets"} onChange={(_ev, data) => {
                        store.logsStore.params.byWallets = data.checked
                    }}></Switch>
                    <Switch label={"Ext-wallets"} onChange={(_ev, data) => {
                        store.logsStore.params.byWalletExtensions = data.checked
                    }}></Switch>
                    <Switch label={"Soft"} onChange={(_ev, data) => {
                        store.logsStore.params.bySoft = data.checked
                    }}></Switch>
                    <Switch label={"Messenger"} onChange={(_ev, data) => {
                        store.logsStore.params.byMessenger = data.checked
                    }}></Switch>
                    <Switch label={"CC"} onChange={(_ev, data) => {
                        store.logsStore.params.byCreditCard = data.checked
                    }}></Switch>
                    <Switch label={"Not downloaded"} onChange={(_ev, data) => {
                        store.logsStore.params.notDownloaded = data.checked
                    }}></Switch>
                    <Switch label={"With notes"} onChange={(_ev, data) => {
                        store.logsStore.params.byNotes = data.checked
                    }}></Switch>
                </Field>
            </div>
            <div className={style.controls}>
                <LoadingButton appearance={"primary"} buttonText={"Search"} state={loadingState}
                               onClick={searchClickHandler}/>
            </div>
        </Card>);
}

/**
 * Имя отображаемое при отладке
 */
LogFilters.displayName = "LogFilters";

export default LogFilters;