export enum FileRequestStateDto {
    /// <summary>
    /// Waiting for processing
    /// </summary>
    Waiting,

    /// <summary>
    /// File processing now
    /// </summary>
    Processing,

    /// <summary>
    /// File processing successfully
    /// </summary>
    Done,

    /// <summary>
    /// File processing done with errors
    /// </summary>
    Error,

    /// <summary>
    /// Nothing is chosen
    /// </summary>
    None
}