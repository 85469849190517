import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    card: {
        minWidth: "300px",
        maxWidth: "500px",
        height: "fit-content",
    },
    cardSessions: {
        margin: "0.25em",
    },
    defButton: {
        minWidth: "200px",
    },
    buttonContainer: {
        display: "flex",
        flexDirection:"column",
        gap: "1em",
        width: "100%",
    }
});