import { makeStyles } from '@fluentui/react-components';

export default makeStyles({
    cardContainer: {
        minWidth: '400px',
        maxWidth: '800px',
        margin: '20px auto',
        padding: '20px',
    },
    statisticContainer: {
        marginTop: '20px',
    },
    statisticDetails: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
    },
    countriesContainer: {
        marginTop: '20px',
    },
    diagram: {
        width: '300px', 
        height: '300px',
        margin: '20px auto',
    },
});