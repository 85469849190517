import React from "react";
import { Layout } from "../../containers/Layout";
import { CookiesRestoreForm } from "../../forms/CookiesRestoreForm";

import useStyle from './RestoreCookiesStyle';

/**
 * Страница восстановления кукисов
 */
const RestoreCookiesPage: React.FC = () => {
    const styles = useStyle();


    return <Layout pageTittle="restore cookies" anotherBackground={true}>
        <div className={styles.RestoreCookiesCards}>
            <CookiesRestoreForm></CookiesRestoreForm>
        </div>
    </Layout>
}

/**
 * Название компонента, используемое при отладке.
 */
RestoreCookiesPage.displayName = "RestoreCookiesPage";
export default RestoreCookiesPage