import React from "react";
import { Layout } from "../../containers/Layout";
import useStyle from "./DownloadPageStyle";
import { DownloadForm } from "../../components/DownloadForm";


/**
 * Компонент-страница "Загрузки"
 * @constructor
 */
const DownloadPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Download"} anotherBackground={true}>
        <div className={styles.BuilderForm}>
        <DownloadForm></DownloadForm>
        </div>
    </Layout>);
}

DownloadPage.displayName = "DownloadPage";
export default DownloadPage;