import React, {useEffect} from "react";
import {LoadingButtonProps} from "./LoadingButtonProps";
import {LoadingState} from "../../models";
import {Button, Spinner} from "@fluentui/react-components";
import {CheckmarkFilled, ErrorCircleRegular} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";

/**
 * Кнопка с возможностью отображения статуса загрузки
 */
const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
    /**
     * Состояние загрузки
     */
    const [loadingState, setLoadingState] = React.useState<LoadingState>("initial");

    /**
     * Обёртка для безопасного вызова обработчика клика переданного в параметрах
     */
    const invokeOnClick = () => {
        if (props.onClick) {
            props.onClick()
        } else {
            console.error("OnClick is empty");
        }
    }

    /**
     * Эффект для обновления состояния кнопки
     */
    useEffect(() => {
        if (props.state) {
            setLoadingState(props.state);
        } else {
            setLoadingState("initial")
        }
    }, [props.state, loadingState]);

    /**
     * Выбор иконки согласно состоянию
     */
    const buttonIcon = () => {
        switch (loadingState) {
            case "initial":
                return props.icon;
            case "loading":
                return <Spinner size="tiny"/>
            case "loaded":
                return <CheckmarkFilled/>
            case "error":
                return <ErrorCircleRegular/>
        }
    }
    
    return (<Button icon={buttonIcon()}
                    appearance={props.appearance}
                    size={props.size}
                    style={props.style}
                    disabled={props.enabled}
                    onClick={invokeOnClick}>{props.buttonText}</Button>);
}

/**
 * Имя компонента отображаемое при отладке
 */
LoadingButton.displayName = "LoadingButton"

export default observer(LoadingButton);