import {
    Card,
    CardHeader,
    PresenceBadge,
    Subtitle2,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import React, {useEffect, useState} from "react";
import useStyles from './ProfileSubscribeInfoStyles';
import {observer} from "mobx-react-lite";
import {useServices, useStore} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {defaultToastId} from "../../shared/constString";
import {SubscriptionInfoDto} from "../../models/subscription";


const ProfileSubscribeInfo: React.FC = () => {
    const styles = useStyles();
    const services = useServices();
    const [subscriptionInfo, setSubscriptionInfo] = useState<SubscriptionInfoDto>();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);
    const stores = useStore();

    useEffect(() => {
        (async () => {
            if (!stores.userStore.isAdmin) {
                try {
                    const subscribeInfo = await services.subscriptionService.GetSubscriptionInfo();
                    setSubscriptionInfo(subscribeInfo.data);
                } catch (error: any) {
                    dispatchToast(
                        <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                            <ToastTitle>Error!</ToastTitle>
                            <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
                        </Toast>,
                        {intent: "error"}
                    );
                }
            }
        })();
    }, [subscriptionInfo, stores.userStore.isAdmin, services.subscriptionService, dispatchToast]);


    if (stores.userStore.isAdmin) {
        return (<>
            <Card className={styles.cardContainer}>
                <CardHeader
                    header={<Subtitle2>Subscription</Subtitle2>}>
                </CardHeader>
                <div>
                    <p>Subscription Status: <PresenceBadge size="small"/> <b>Active</b></p>

                    <b>You have administrator privileges.</b>
                </div>
            </Card>
            <Toaster toasterId={toasterId}/>
        </>);
    } else {
        return (<>
                <Card className={styles.cardContainer}>
                    <CardHeader
                        header={<Subtitle2>Subscription</Subtitle2>}>
                    </CardHeader>
                    <div>
                        <p>Subscription Status: <PresenceBadge size="small"/> <b>Active</b></p>
                        <p>Active until : <b>20.04.2021</b></p>
                        <p>Subscription plan : <b>Standard</b></p>
                    </div>
                </Card>
                <Toaster toasterId={toasterId}/>
            </>
        );
    }
}

ProfileSubscribeInfo.displayName = "ProfileSubscribeInfo";

export default observer(ProfileSubscribeInfo);