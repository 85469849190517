import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    noItems: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: "2em"
    }
});