import {UserStore} from './userStore';
import {LayoutStore} from "./layoutStore";
import {LoadingStore} from "./loaderStore";
import {LogsStore} from "./logsStore";

/**
 * Корневое хранилище
 */
export class RootStore {

    /**
     * Хранилище состояния шаблона
     */
    public readonly layoutStore: LayoutStore;

    /**
     * Хранилище состояния информации о пользователе.
     */
    public readonly userStore: UserStore;

    /**
     * Хранилище загрузчика
     */
    public readonly loaderStore: LoadingStore;

    /**
     * Хранилище списка логов
     */
    public readonly logsStore: LogsStore;

    /**
     * Конструктор.
     */
    constructor() {
        this.userStore = new UserStore();
        this.layoutStore = new LayoutStore();
        this.loaderStore = new LoadingStore();
        this.logsStore = new LogsStore();
    }
}