import React from "react";
import { Layout } from "../../containers/Layout";
import useStyle from "./BotInfoPageStyles";
import { BotInfoForm } from "../../components/BotInfoForm";

/**
 * Компонент-страница "подробнее о боте"
 * @constructor
 */
const BotInfoPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"BotInfo"} anotherBackground={true}>
        <div className={styles.BotInfo}>
            <BotInfoForm></BotInfoForm>
        </div>
    </Layout>);
}

BotInfoPage.displayName = "BotInfoPage";
export default BotInfoPage;