import React from "react";
import {Avatar, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger} from "@fluentui/react-components";
import {useServices, useStore} from "../../hooks";
import {observer} from "mobx-react-lite";
import useStyles from "./UserHeaderButtonStyle";
import {DoorArrowRightRegular, KeyResetRegular, PersonRegular} from "@fluentui/react-icons";
import {useNavigate} from "react-router-dom";
import {CHANGE_PASSWORD_URL, PROFILE_URL} from "../../shared/constUrl";

/**
 * Кнопка с аккаунт меню
 */
const UserHeaderButton: React.FC = () => {
    const stores = useStore();
    const styles = useStyles();
    const navigate = useNavigate();
    const services = useServices();

    /**
     * Выход из аккаунта пользователя
     */
    const onLogOut = async () => {
        try {
            await services.authorizationService.logOut({
                refreshToken: services.tokenService.refreshToken,
                userId: stores.userStore.userId
            });
        } catch (error: any) {
            console.log(error);
        } finally {
            services.tokenService.removeTokens();
            stores.userStore.isAuthorized = false;
        }
    };

    /**
     * Переход на страницу профиля
     */
    const onProfileClick = () => {
        navigate(PROFILE_URL);
    }

    /**
     * Переход на страницу смены пароля
     */
    const onChangePasswordClick = () => {
        navigate(CHANGE_PASSWORD_URL);
    }

    return (<>
        <Menu closeOnScroll={true}>
            <MenuTrigger>
                <Avatar className={styles.avatar} active={"active"} activeAppearance={"ring-shadow"} color={"colorful"}
                        size={24}
                        name={stores.userStore.userName}>User</Avatar>
            </MenuTrigger>
            <MenuPopover className={styles.popUpMenu}>
                <MenuList>
                    <MenuItem icon={<PersonRegular/>} onClick={onProfileClick}>Profile</MenuItem>
                    <MenuItem icon={<KeyResetRegular/>} onClick={onChangePasswordClick}>Change password</MenuItem>
                    <MenuItem icon={<DoorArrowRightRegular/>} onClick={onLogOut}>Log out</MenuItem>
                </MenuList>
            </MenuPopover>
        </Menu>
    </>);
}

/**
 * Название компонента, отображаемое при отладке.
 */
UserHeaderButton.displayName = 'UserHeaderButton';

export default observer(UserHeaderButton);