import {AxiosService} from "./axios";
import {ChangePasswordDto} from "../models/account";

/**
 * Сервис работы с Аккаунтом пользователя
 */
export class AccountService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор аккаунт сервиса
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Запрос на изменение пароля
     * @param body Модель данных для изменения пароля
     */
    async changePassword(body: ChangePasswordDto): Promise<void> {
        try {
            await this.axios.post("/account/change-password", body);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}