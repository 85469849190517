export enum RequestFileTypeDto {
    AllFiles,
    MultipleBot,
    Bot,
    Grabber,
    Sys,
    CommonFile,
    Soft,
    Wallet,
    Messenger,
    None
}