import AxiosService from "./axios/axiosService";
import {UserInfoAdminDto} from "../models/user/userInfoAdminDto";
import {ApiResult} from "../models";
import {CreateUserDto} from "../models/account";

export class AdminUserService {

    /**
     * Axios сервис
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список профилей
     */
    async getUsers(): Promise<UserInfoAdminDto[]> {
        try {
            const response = await this.axios.get("/admin/users/show/all");
            return Promise.resolve(response.data as UserInfoAdminDto[]);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Создать пользователя
     * @return Строка с Id пользователя
     */
    async create(newUser: CreateUserDto): Promise<string> {
        try {
            const response = await this.axios.post("/user/new-user/create", newUser) as ApiResult<any>;
            return response.data;
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Удаление пользователя
     * @param userId ИД пользователя
     */
    async delete(userId: number): Promise<void> {
        try {
            await this.axios.delete("/admin/users/delete", {id: userId});
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}