import {ApiResult} from "../models";
import {
    BCountByCounty,
    CreateLinkDto,
    SharedLinkViewModel,
    StatisticViewModel,
    StatsQueryDto
} from "../models/statistic";
import {AxiosService} from "./axios";

export class StatisticService {

    /**
     * Экземпляр axios сервиса
     * @private
     */
    private axios: AxiosService;

    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * запрос данных статистики если существует
     */
    async getStatisticData(body: StatsQueryDto): Promise<ApiResult<StatisticViewModel>> {
        try {
            const resp = await this.axios.post<StatisticViewModel>("/statistic/get-data", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить топ три страны
     */
    async getTopThreeCountries(body: StatsQueryDto): Promise<ApiResult<BCountByCounty[]>> {
        try {
            const resp = await this.axios.post<BCountByCounty[]>("/statistic/get-top-3-countries", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить статистику по странам
     */
    async getCountries(body: StatsQueryDto): Promise<ApiResult<BCountByCounty[]>> {
        try {
            const resp = await this.axios.post<BCountByCounty[]>("/statistic/get-by-countries", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить полный список стран отсортированный по количеству логов
     */
    async getAllCountriesSorted(body: StatsQueryDto): Promise<ApiResult<BCountByCounty[]>> {
        try {
            const resp = await this.axios.post<BCountByCounty[]>("/statistic/get-all-top-countries", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * создать публичную ссылку на статистику
     */
    async createStatisticLink(body: CreateLinkDto): Promise<ApiResult<string>> {
        try {
            const resp = await this.axios.post<string>("/statistic-shared-links/create", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * проверить существуют ли ссылки
     */
    async isHaveStatisticLinks(): Promise<ApiResult<boolean>> {
        try {
            const resp = await this.axios.get<boolean>("/statistic-shared-links/is-have-links");
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * получить все ссылки
     */
    async getStatisticLink(): Promise<ApiResult<SharedLinkViewModel>> {
        try {
            const resp = await this.axios.get<SharedLinkViewModel>("/statistic-shared-links/manage");
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * удалить ссылку
     */
    async deleteStatisticLink(body: string): Promise<ApiResult<SharedLinkViewModel>> {
        try {
            const resp = await this.axios.post<SharedLinkViewModel>("/statistic-shared-links/delete", body);
            return Promise.resolve(resp);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}