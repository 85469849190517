import {makeStyles} from "@fluentui/react-components";

export default makeStyles({
    profileCards: {
        display: "grid",
        gridGap: "1em",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridAutoRows: "minmax(100px,auto)",
        gridAutoFlow: "dense",
    },
});