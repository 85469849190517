import React from "react";
import { Card } from "@fluentui/react-components";
import UseStyle from "./BotInfoFormStyles";
import { InfoCardProps } from "../../models/botInfo";

const InfoCard: React.FC<InfoCardProps> = (prop) => {
  const styles = UseStyle();

  const onClickHandler = () => {
    if (prop.onClick) {
      prop.onClick();
    }
  };

  return (
    <Card
      className={styles.infoCard}
      style={{ backgroundColor: prop.color }}
      onClick={onClickHandler}
    >
      <div className={styles.cardContent}>
        <div className={styles.cardIcon}>{prop.icon}</div>
        <div>
          <h2>{prop.count}</h2>
          <p>{prop.title}</p>
        </div>
      </div>
    </Card>
  );
};

export default InfoCard;