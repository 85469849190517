import {AxiosService} from "./axios";
import {JsonProfilePageDto} from "../models/jsonProfiles/JsonProfilePageDto";

export class AdminJsonProfilesService {

    /**
     * Axios сервис
     * @private
     */
    private axios: AxiosService;

    /**
     * Конструктор
     */
    constructor() {
        this.axios = new AxiosService();
    }

    /**
     * Получить список профилей
     */
    async getList(): Promise<JsonProfilePageDto> {
        try {
            const response = await this.axios.get("/admin/json-profiles");
            return Promise.resolve(response.data as JsonProfilePageDto);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }

    /**
     * Загрузка файла
     */
    async uploadFile(formData: FormData, userId: string): Promise<void> {
        try {
            console.log(formData);
            await this.axios.uploadFile("/admin/json-profiles/upload/" + userId, formData);
        } catch (error: any) {
            return Promise.reject(error);
        }
    }
}
