import * as Yup from 'yup';

export const validationSchema = () =>
    Yup.object().shape({
        username: Yup.string().required('- username is required'),
        password: Yup.string().required('- password is required'),
    });

export const getValidationMessage = (errors: any = {}) => {
    let message = '';

    if (errors.username) {
        message += errors.username;
    }

    if (errors.password) {
        message += (message ? '\n' : '') + errors.password;
    }

    if (message) {
        message = 'Errors: \n' + message;
    }

    return message;
};