import React from "react";
import UserSession from "../../models/sessions/UserSession";
import {dateToMskString} from "../../utils/DateUtils";

export interface userRecordProp {
    userSessions: UserSession[];
}

const SessionRecord: React.FC<userRecordProp> = (prop: userRecordProp) => {

    const rec = () => {
        return prop.userSessions.map((ei, index) => {
            return <div key={index}><b>{(index + 1).toString()}</b>. {dateToMskString(new Date(ei.created))}</div>;
        });
    }

    return (<>
        {rec()}
    </>);
};

SessionRecord.displayName = "SessionRecord";

export default SessionRecord;