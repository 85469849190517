import {makeAutoObservable} from 'mobx';

/**
 * Хранилище данных для шаблона
 */
export class LayoutStore {
    /**
     * Флаг для обозначения состояния меню.
     */
    isMenuDrawerIsOpen: boolean = true;

    /**
     * Конструктор
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Устанавливает статус бокового меня "открыто/закрыто"
     * @param value Желаемый статус
     */
    setMenuDrawerIsOpen(value: boolean) {
        this.isMenuDrawerIsOpen = value;
    }
}