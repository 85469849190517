import React from "react";
import { Layout } from "../../containers/Layout";
import useStyle from "./BuilderPageStyle";
import { BuilderForm } from "../../forms/BuilderForm";
import BuilderStatusForm from "../../forms/BuilderForm/BuilderStatusForm";
import BuilderHistoryForm from "../../forms/BuilderForm/BuilderHistoryForm";

/**
 * Компонент-страница "Билдер"
 * @constructor
 */
const BuilderPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Builder"} anotherBackground={true}>
        <div className={styles.BuilderForm}>
            <BuilderStatusForm></BuilderStatusForm>
            <BuilderForm></BuilderForm>
            <BuilderHistoryForm></BuilderHistoryForm>
        </div>
    </Layout>);
}

BuilderPage.displayName = "BuilderPage";
export default BuilderPage;