import React from "react";
import {Layout} from "../../../containers/Layout";
import CreateUserForm from "../../../forms/CreateUserForm";

/**
 * Страница создания пользователя
 * @constructor
 */
const CreateUserPage: React.FC = () => {
    return (
        <Layout pageTittle={"Create user"} anotherBackground={true}>
            <CreateUserForm/>
        </Layout>
    );
}

/**
 * Имя отображаемое при отладке
 */
CreateUserPage.displayName = "CreateUserPage";

export default CreateUserPage;