import React from "react";
import {
    Card,
    CardFooter,
    Field,
    Input,
    Label,
    Select,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import {LoadingButton} from "../../components/ButtonWithLoadingState";
import {LoadingState} from "../../models";
import {useServices} from "../../hooks";
import {defaultToastId} from "../../shared/constString";
import {GetMessageByCode} from "../../utils";
import {UserRole} from "../../models/account";

/**
 * Форма создания пользователя
 * @constructor
 */
const CreateUserForm: React.FC = () => {
    const services = useServices();
    const [loading, setLoading] = React.useState<LoadingState>("initial");
    const [newUsername, setUsername] = React.useState("");
    const [newPassword, setPassword] = React.useState("");
    const [newRole, setRole] = React.useState<UserRole>(UserRole.PartnerRole);
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    const usernameChangeHandler = (_ev: any, data: any) => {

        setUsername(data.value);
    };

    const handleSelectChange = (event: any) => {
        setRole(event.target.value);
    };


    const submitHandler = async () => {
        try {
            setLoading("loading");
            const response = await services.adminUserService.create({
                username: newUsername,
                password: newPassword,
                role: newRole
            });
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Done.</ToastTitle>
                    <ToastBody>New user has been created. ID: {response}</ToastBody>
                </Toast>,
                {intent: "success"}
            );
            setLoading("loaded");
        } catch (error: any) {
            setLoading("error");
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    return (
        <>
            <Card style={{maxWidth: "50%"}}>
                <div>
                    <Label>Username</Label>
                    <Field>
                        <Input type={"text"} onChange={usernameChangeHandler}></Input>
                    </Field>
                </div>
                <div>
                    <Label>Password</Label>
                    <Field>
                        <Input onChange={(_ev, data) => {
                            setPassword(data.value);
                        }} type={"text"}></Input>
                    </Field>
                </div>

                <div>
                    <Label>Role</Label>
                    <Field>
                        <Select value={newRole} onChange={handleSelectChange}>
                            <option value={UserRole.PartnerRole}>Traffer</option>
                            <option value={UserRole.UserRole}>User</option>
                        </Select>
                    </Field>
                </div>
                <CardFooter>
                    <LoadingButton appearance={"primary"} buttonText={"Create"} state={loading}
                                   onClick={() => submitHandler()}/>
                </CardFooter>
            </Card>
            <Toaster toasterId={toasterId}/>
        </>
    );
}

/**
 * Имя отображаемое при отладке
 */
CreateUserForm.displayName = "CreateUserForm";

export default CreateUserForm;