import {makeStyles, tokens} from "@fluentui/react-components";

export default makeStyles({
    container: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        minWidth: "100vw",
        justifyContent: "center",
        background: "#eaeaea",
    },
    titleAcr: {
        margin: "10px",
        padding: "10px",
        color: "GrayText",
    },
    card: {
        width: "400px",
    },
    invertedWrapper: {
        backgroundColor: tokens.colorBrandBackgroundStatic,
    },
});