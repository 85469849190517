import {
    Button,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridProps,
    DataGridRow,
    Input,
    Link,
    Select,
    SelectProps,
    Text,
    Toast,
    ToastBody,
    Toaster,
    ToastFooter,
    ToastTitle,
    tokens,
    useId,
    useToastController
} from "@fluentui/react-components";
import {
    ArrowDownload20Regular,
    ArrowRight20Regular,
    BinRecycle20Filled,
    BinRecycle20Regular
} from "@fluentui/react-icons";
import {observer} from "mobx-react-lite";
import useStyles from "./LogsListStyle";
import React, {useCallback, useEffect, useState} from "react";
import Log from "../../models/logs/Log";
import {useServices, useStore} from "../../hooks";
import {LogTableColumns} from "./LogTableColumns";
import {columnSizingOptions} from "./LogListColumnSizeOptions";
import {LoadingButton} from "../ButtonWithLoadingState";
import {GetMessageByCode} from "../../utils";
import {defaultToastId} from "../../shared/constString";
import PaginationButtons from "../PaginationButtons/PaginationButtons";
import {ApiError} from "../../models";
import {SelectedCount} from "../SelectedCount";
import {TableRowId} from "@fluentui/react-table";
import {DOWNLOADS_URL} from "../../shared/constUrl";
import NoItems from "../NoItems/NoItems";

/**
 * Компонент отображения списка логов
 * @constructor
 */
const LogsList: React.FC = () => {
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);
    const styles = useStyles();
    const selectId = useId();
    const services = useServices();
    const store = useStore();
    const [nextPage, setNextPage] = useState<number>(0);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [selectedItems, setSelectedItems] = useState<Set<TableRowId>>(new Set());

    /**
     * Используется для предотвращения срабатывания эффектов которые зависят от параметров
     */
    const [initialLoad, setInitialLoad] = useState(true);

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = useCallback((error: ApiError) => {
        dispatchToast(
            <Toast style={{minHeight: "50px", minWidth: "100px"}}>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }, [dispatchToast]);


    /**
     * Загрузка опций для фильтрации
     */
    useEffect(() => {
        (async () => {
            try {
                store.logsStore.options = await services.logsService.getOptions();
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
    }, [services.logsService, store.loaderStore]);

    /**
     * Эффект для первоначальной загрузки данных
     */
    useEffect(() => {
        (async () => {
            try {
                store.loaderStore.setLoading(true);
                const response = await services.logsService.getLogsList(store.logsStore.params);
                store.logsStore.updateData(response);
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
                setInitialLoad(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Эффект для обработки изменения номера страницы или размера
     */
    useEffect(() => {
        if (initialLoad) return;
        (async () => {
            try {
                store.loaderStore.setLoading(true);
                const response = await services.logsService.getLogsList(store.logsStore.params)
                store.logsStore.updateData(response);
            } catch (error: any) {
                showErrorNotify(error);
            } finally {
                store.loaderStore.setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store.logsStore.params.pageSize,
        store.logsStore.params.pageNumber,
        showErrorNotify]);

    /**
     * Обработчик кнопки "Скачать"
     */
    const onDownloadButton = async () => {
        try {
            store.loaderStore.setLoading(true);
            if (selectedItems && selectedItems.size > 0) {
                await services.fileDownloadService.requestLogsFiles(getLogIdsFromSelected());
            } else {
                await services.fileDownloadService.requestAllLogFiles();
            }
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                    <ToastTitle>Downloading file</ToastTitle>
                    <ToastBody>
                        <Text>This may take a while. File requested and preparing for you</Text>
                    </ToastBody>
                    <ToastFooter>
                        <Link href={DOWNLOADS_URL}>Open download page</Link>
                    </ToastFooter>
                </Toast>,
                {intent: "success"}
            );
        } catch (error: any) {
            console.error(error);
            showErrorNotify(error);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    /**
     * Обработчик кнопки "Следующая страница"
     */
    const onNextClickHandler = async () => {
        if (store.logsStore.params) {
            const value = (store.logsStore.params.pageNumber) ? store.logsStore.params.pageNumber + 1 : 1;
            store.logsStore.params = {...store.logsStore.params, pageNumber: value};
        }
    }

    /**
     * Обработчик кнопки "Предыдущая страница"
     */
    const onPrevClickHandler = async () => {
        if (store.logsStore.params) {
            const value = (store.logsStore.params.pageNumber && store.logsStore.params.pageNumber > 1) ? store.logsStore.params.pageNumber - 1 : 1;
            store.logsStore.params = {...store.logsStore.params, pageNumber: value};
        }
    }

    /**
     * Выбор стиля в зависимости от характеристик лога
     * Например: дубликат, скачан или нет
     * @param item
     */
    const chooseStyle = (item: Log) => {
        if (item.isDuplicate && item.isDownloaded) {
            return styles.downloadedDuplicate;
        }

        if (item.isDownloaded && !item.isDuplicate) {
            return styles.downloaded;
        }

        return styles.normalRow;
    }

    /**
     * Обработчик изменения размера таблицы
     * @param _event событие
     * @param data данные
     */
    const onSizeChangeHandler: SelectProps["onChange"] = (_event, data) => {
        const numberValue = parseInt(data.value);
        if (numberValue !== store.logsStore.params.pageSize) {
            store.logsStore.paging = {...store.logsStore.paging, pageNumber: 1};
            store.logsStore.params = {...store.logsStore.params, pageSize: numberValue, pageNumber: 1};
        }
    };

    /**
     * Обработчик нажатий клавиш на Input с номером страницы
     */
    const onClickGoPage = () => {
        if (nextPage > 0 && nextPage <= Number(store.logsStore.paging.totalPages)) {
            store.logsStore.params = {...store.logsStore.params, pageNumber: nextPage};
        } else {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>Invalid input. Please check!</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    /**
     * Отображение количества выбранных элементов
     */
    const showSelectedCount = () => {
        if (selectedCount > 0) {
            return <SelectedCount count={selectedCount}/>
        }
        return;
    }

    /**
     * Обработчик выбора элементов на таблице
     */
    const onSelectionChange: DataGridProps["onSelectionChange"] = (_e, data) => {
        setSelectedCount(data.selectedItems.size);
        setSelectedItems(data.selectedItems);
    }

    /**
     * Получаем идентификаторы логов с отмеченных элементов
     */
    const getLogIdsFromSelected = (): number[] => {
        const selectedIds = Array.from(selectedItems)
            .map(id => Number(id))
            .filter(id => !isNaN(id));
        const selectedLogs = store.logsStore.logs.filter(log => selectedIds.includes(log.id));
        return selectedLogs.map(log => log.id);
    };

    /**
     * Обработчик нажатия кнопки удалить пустые логи
     */
    const deleteEmptyHandler = async () => {
        try {
            store.loaderStore.setLoading(true);
            await services.logsService.deleteEmpty();
        } catch (e: any) {
            showErrorNotify(e);
        } finally {
            store.loaderStore.setLoading(false);
        }
    };

    return (
        <div className={styles.gridContainer}>
            <div className={styles.controlContainer}>
                <div className={styles.controlButtons}>
                    <LoadingButton
                        buttonText={"Download"}
                        icon=<ArrowDownload20Regular/>
                        style={{padding: '0.1em', marginBottom: '0.3em'}}
                        onClick={onDownloadButton}
                    />

                    <LoadingButton size={"small"}
                                   buttonText={"Delete"}
                                   style={{
                                       padding: '0.1em',
                                       marginBottom: '0.3em',
                                       color: tokens.colorPaletteDarkOrangeForeground1
                                   }}
                                   onClick={() => alert("not implemented")}
                                   icon=<BinRecycle20Filled/>/>

                    <LoadingButton buttonText={"Delete empty"}
                                   icon=<BinRecycle20Regular/>
                                   onClick={deleteEmptyHandler}
                                   style={{padding: '0.1em', marginBottom: '0.3em'}}
                    />
                    {showSelectedCount()}
                </div>
                <div className={styles.controlPagination}>
                    <Select id={selectId} onChange={onSizeChangeHandler} value={store.logsStore.paging.pageSize}>
                        <option>10</option>
                        <option>25</option>
                        <option>50</option>
                        <option>75</option>
                        <option>100</option>
                        <option>200</option>
                        <option>300</option>
                    </Select>
                    <Input type={"number"} min={1} placeholder={"Go to:"}
                           style={{marginBottom: '0.3em'}}
                           onChange={(_e, d) => {
                               setNextPage(Number(d.value))
                           }}
                           contentAfter={<Button icon={<ArrowRight20Regular/>}
                                                 style={{margin: "0.1em"}}
                                                 appearance={"transparent"} onClick={onClickGoPage}/>}></Input>
                    <PaginationButtons currentPage={store.logsStore.paging.pageNumber ?? 1}
                                       totalPages={store.logsStore.paging.totalPages ?? 1}
                                       totalCount={store.logsStore.paging.totalItems ?? 1}
                                       onNextClick={onNextClickHandler}
                                       onPrevClick={onPrevClickHandler}/>
                </div>
            </div>
            <DataGrid className={styles.dataGrid}
                      items={store.logsStore.logs}
                      columns={LogTableColumns}
                      resizableColumnsOptions={{autoFitColumns: true}}
                      getRowId={(item) => item.id}
                      focusMode="cell"
                      size={"small"}
                      selectionMode={"multiselect"}
                      as={"div"}
                      resizableColumns={true}
                      columnSizingOptions={columnSizingOptions}
                      onSelectionChange={onSelectionChange}
            >
                <DataGridHeader as={"div"}>
                    <DataGridRow
                        selectionCell={{
                            checkboxIndicator: {"aria-label": "Select all rows"},
                        }}
                    >
                        {({renderHeaderCell}) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                {(store.logsStore.paging.totalItems === 0) ?
                    <NoItems/> :
                    <DataGridBody<Log>>
                        {({item, rowId}) => (
                            <DataGridRow<Log>
                                key={rowId}
                                selectionCell={{
                                    checkboxIndicator: {"aria-label": "Select row"},
                                }}
                                className={chooseStyle(item)}
                            >
                                {({renderCell}) => (
                                    <DataGridCell as={"div"}>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>}
            </DataGrid>
            <Toaster toasterId={toasterId}/>
        </div>
    );
};

/**
 * Имя отображаемое при отладке
 */
LogsList.displayName = 'LogsList';

export default observer(LogsList);