import React from "react";
import { Layout } from "../../containers/Layout";
import useStyle from "./StatisticPageStyle";
import { StatisticForm, StatisticSharedForm } from "../../components/StatisticForm";


/**
 * Компонент-страница "Билдер"
 * @constructor
 */
const StatisticPage: React.FC = () => {
    const styles = useStyle();
    return (<Layout pageTittle={"Statistic"} anotherBackground={true}>
        <div className={styles.StatisticPageStyle}>
            <StatisticSharedForm></StatisticSharedForm>
            <StatisticForm></StatisticForm>
        </div>
    </Layout>);
}

StatisticPage.displayName = "StatisticPage";
export default StatisticPage;