import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardHeader,
    Label,
    Subtitle2,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableRow,
    Text,
    Toast,
    ToastBody,
    ToastTitle,
    useId,
    useToastController
} from '@fluentui/react-components';
import UseStyle from './BuilderFormStyles';
import {useServices} from '../../hooks';
import {BuilderHistoryItemDto} from '../../models/builder';
import {ErrorCode} from '../../models/ErrorCode';
import {defaultToastId} from "../../shared/constString";
import {dateToMskString} from "../../utils/DateUtils";

//форма с историей всех билдов
const BuilderHistoryForm: React.FC = () => {
    const styles = UseStyle();
    const [historyItems, setHistoryItems] = useState<BuilderHistoryItemDto[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    // получение истории с апи

    const fetchHistory = useCallback(async () => {
        try {
            const response = await services.builderService.previous();
            setHistoryItems(response.data);
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: '50px', minWidth: '200px'}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.message : error.code}</ToastBody>
                </Toast>,
                {intent: 'error'}
            );
        } finally {
            setLoading(false);
        }
    }, [services.builderService, setHistoryItems, dispatchToast, setLoading]);

    useEffect(() => {
         fetchHistory();
    }, [fetchHistory]);


    // таймер для обновления истории
    useEffect(() => {
        const intervalId = setInterval(fetchHistory, 5000);

        return () => clearInterval(intervalId);
    }, [fetchHistory]);

    //кнопка скачивания файла
    const handleDownload = async (requestId: string) => {
        try {
            await services.builderService.downloadBuild(requestId);
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.messasge : error.code}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };
//кнопка удаления файла
    const handleDelete = async (requestId: string) => {
        try {
            await services.builderService.deleteBuild(requestId);
            await fetchHistory();
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>GetMessageByCode(error.code)</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    if (loading) {
        return <Text>Loading...</Text>;
    }

    if (error) {
        return <Text>{error}</Text>;
    }

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Subtitle2>Build History</Subtitle2>}/>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableCell><Label>Request ID</Label></TableCell>
                        <TableCell><Label>Done At</Label></TableCell>
                        <TableCell><Label>File Name</Label></TableCell>
                        <TableCell><Label>Action</Label></TableCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {historyItems.length > 0 ? (
                        historyItems.map((item, index) => (
                            <TableRow key={index}>
                                <TableCell><Text>{item.requestId}</Text></TableCell>
                                <TableCell><Text>{dateToMskString(new Date(item.doneAt))}</Text></TableCell>
                                <TableCell><Text>{item.fileName}</Text></TableCell>
                                <TableCell>
                                    <Button onClick={() => handleDownload(item.requestId)}
                                            style={{marginRight: '8px'}}>Download</Button>
                                    <Button onClick={() => handleDelete(item.requestId)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={4}><Text>No history available.</Text></TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Card>
    );
};

export default BuilderHistoryForm;
