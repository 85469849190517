import {makeAutoObservable, runInAction} from 'mobx';

/**
 * Хранилище состояния загрузки
 */
export class LoadingStore {
    /**
     * Состояние загрузки
     * @private
     */
    private _loading: boolean = false;

    /**
     * Получить состояние загрузки
     */
    public get loading(): boolean {
        return this._loading;
    }

    /**
     * Установить состояние загрузки
     * @param loading
     */
    public setLoading = (loading: boolean) => {
        runInAction(() => {
            this._loading = loading;
        });
    };

    /**
     * Признак, что сейчас происходит обновление токенов
     * @private
     */
    private _tokenRefreshing: boolean = false;

    /**
     * Получить состояние загрузки
     */
    public get tokenRefreshing(): boolean {
        return this._tokenRefreshing;
    }

    /**
     * Установить состояние обновление токенов
     * @param value
     */
    public setTokenRefreshing = (value: boolean) => {
        runInAction(() => {
            this._tokenRefreshing = value;
        });
    };

    constructor() {
        makeAutoObservable(this);
    }
}
