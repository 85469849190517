import {LogDeleteButtonProps} from "./LogDeleteButtonProp";
import {Toast, ToastBody, ToastTitle, tokens, useId, useToastController} from "@fluentui/react-components";
import {DeleteRegular} from "@fluentui/react-icons";
import React, {useState} from "react";
import {LoadingButton} from "../../ButtonWithLoadingState";
import {ApiError, LoadingState} from "../../../models";
import {useServices, useStore} from "../../../hooks";
import {defaultToastId} from "../../../shared/constString";
import {GetMessageByCode} from "../../../utils";
import {observer} from "mobx-react-lite";

/**
 * Кнопка перемещения лога в корзину
 * @param props параметры кнопки
 * @constructor
 */
const LogDeleteButton: React.FC<LogDeleteButtonProps> = (props) => {
    const [currentState, setCurrentState] = useState<LoadingState>("initial");
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);
    const store = useStore();

    /**
     * Обработчик нажатия по кнопке удаления
     */
    const onClickHandler = () => {
        (async () => {
            try {
                setCurrentState("loading");
                await services.logsService.moveToBin(props.logId);
                setCurrentState("loaded");
                store.logsStore.removeItemById(props.logId);
            } catch (error: any) {
                console.error(error);
                setCurrentState("error");
                showErrorNotify(error);
            }
        })();
    };

    /**
     * Показ сообщения об ошибке
     * @param error Модель с ошибкой
     */
    const showErrorNotify = (error: ApiError) => {
        dispatchToast(
            <Toast style={{minHeight: 50 + "px", minWidth: 100 + "px"}}>
                <ToastTitle>Error!</ToastTitle>
                <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
            </Toast>,
            {intent: "error"}
        );
    }

    return (<LoadingButton aria-label="Delete"
                           style={{color: tokens.colorPaletteDarkOrangeForeground1}}
                           appearance={"subtle"}
                           state={currentState}
                           onClick={onClickHandler}
                           icon={<DeleteRegular/>}/>);
}

/**
 * Имя отображаемое при отладке
 */
LogDeleteButton.displayName = "LogDeleteButton";

export default observer(LogDeleteButton);