import {
    Button,
    Card,
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    Field,
    Input,
    Select,
} from "@fluentui/react-components";
import {NoItems} from "../NoItems";
import {CookieItemDto, CookiesPageDto} from "../../models/cookies";
import {useSearchParams} from "react-router-dom";
import React, {useCallback, useEffect, useState} from "react";
import {useServices} from "../../hooks";
import {CookieTableColumns} from "./CookieTableColumns";
import {columnSizingOptions} from "./CookieColumnSizes";
import {PaginationButtons} from "../PaginationButtons";
import {LoadingState} from "../../models";
import {LoadingButton} from "../ButtonWithLoadingState";
import {COOKIE_LIST_URL} from "../../shared/constUrl";

/**
 * Компонент отображения списка Cookies
 */
const CookieList: React.FC = () => {
    const [searchParams] = useSearchParams();
    const services = useServices();
    const [cookiesItems, setCookieItems] =
        useState<CookiesPageDto>(defaultCookiesItems);
    const [loadingState, setLoadingState] = useState<LoadingState>("initial");
    const [options, setOptions] = useState<string[]>([]);
    const [browser, setBrowser] = useState<string>('');
    const [keyword, setKeyword] = useState<string>();

    /**
     * Получение опций для фильтрации
     */
    const getSearchOptions = useCallback(async () => {
        const options = await services.cookiesService.getOptions();
        setOptions(options);
        setBrowser(options[0]);
    }, [services.cookiesService]);

    /**
     * Получение Cookies
     */
    const getCookies = useCallback(async () => {
        const cookiesPage = await services.cookiesService.getList();
        setCookieItems(cookiesPage);
    }, [services.cookiesService]);

    /**
     * логика скачивания файла
     */
    const handleDownload = async () => {
        const logId = searchParams.get('botId') || '0';
        const params = new URLSearchParams({
            botId: logId,
            browserName: browser,
            keyword: keyword ?? ''
        });

        // Формируем полный URL с параметрами
        const urlWithParams = `${COOKIE_LIST_URL}/download?${params.toString()}`;
        await services.fileDownloadService.downloadFile(urlWithParams);
    };

    /**
     * Обработчик нажатия кнопки "Поиск"
     */
    const searchClickHandler = async () => {
        try {
            setLoadingState("loading");
            const response = await services.cookiesService.getList({
                pageSize: cookiesItems.paging.pageSize,
                pageNumber: 0,
                botId: Number(searchParams.get('botId')) || 0,
                browserName: browser,
                keyword: keyword || ''
            });
            setCookieItems(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    };

    const onNextClick = async () => {
        try {
            const response = await services.cookiesService.getList({
                pageSize: cookiesItems.paging.pageSize,
                pageNumber: (cookiesItems.paging.pageNumber + 1),
                botId: Number(searchParams.get('botId')) || 0,
                browserName: browser,
                keyword: keyword || ''
            });
            setCookieItems(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    }

    const onPrevClick = async () => {
        try {
            const response = await services.cookiesService.getList({
                pageSize: cookiesItems.paging.pageSize,
                pageNumber: (cookiesItems.paging.pageNumber - 1),
                botId: Number(searchParams.get('botId')) || 0,
                browserName: browser,
                keyword: keyword || ''
            });
            setCookieItems(response);
            setLoadingState("loaded");
        } catch (error: any) {
            setLoadingState("error");
        }
    }

    /**
     * Загрузка списка Cookies и опций для фильтрации
     */
    useEffect(() => {
        getCookies();
        getSearchOptions();
    }, [getCookies, getSearchOptions]);

    /**
     * Обработчик выбора элемента
     */
    const handleSelectChange = (event: any) => {
        const value = event.target.value;
        setBrowser(value); // Сохраняем выбранное значение в состояние
    };

    /**
     * Обработчик ввода текста
     * @param event
     */
    const handleInputChange = (event: any) => {
        setKeyword(event.target.value); // Сохраняем введенное значение в состояние
    };

    return (
        <>
            <div>
                <Card style={{margin: "1em"}}>
                    <Field label="Browser name">
                        <Select onChange={handleSelectChange}>
                            {options.map((val) => (
                                <option key={val} value={val}>{val}</option>
                            ))}
                        </Select>
                    </Field>

                    <Field label="Keyword">
                        <Input onChange={handleInputChange}></Input>
                    </Field>
                    <div>
                        <LoadingButton
                            appearance={"primary"}
                            buttonText={"Search"}
                            state={loadingState}
                            onClick={searchClickHandler}
                        />
                        <Button
                            style={{marginLeft: "1em"}}
                            onClick={() => handleDownload()}
                        >
                            Download
                        </Button>
                    </div>
                </Card>
                <div style={{margin: "1em"}}>
                    <PaginationButtons
                        currentPage={cookiesItems.paging.pageNumber}
                        totalCount={cookiesItems.paging.totalItems}
                        totalPages={cookiesItems.paging.totalPages}
                        onNextClick={onNextClick}
                        onPrevClick={onPrevClick}
                    />
                </div>
            </div>
            <DataGrid
                style={{margin: "1em"}}
                items={cookiesItems.cookies}
                as={"div"}
                size={"small"}
                resizableColumns={true}
                containerWidthOffset={1}
                columnSizingOptions={columnSizingOptions}
                columns={CookieTableColumns}
            >
                <DataGridHeader as={"div"}>
                    <DataGridRow>
                        {({renderHeaderCell}) => (
                            <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                {cookiesItems.paging.totalItems === 0 ? (
                    <NoItems/>
                ) : (
                    <DataGridBody<CookieItemDto>>
                        {({item, rowId}) => (
                            <DataGridRow<CookieItemDto> key={rowId}>
                                {({renderCell}) => (
                                    <DataGridCell as={"div"}>{renderCell(item)}</DataGridCell>
                                )}
                            </DataGridRow>
                        )}
                    </DataGridBody>
                )}
            </DataGrid>
        </>
    );
};

const defaultCookiesItems: CookiesPageDto = {
    cookies: [],
    paging: {
        pageNumber: 1,
        pageSize: 50,
        totalItems: 0,
        totalPages: 0,
    },
};

/**
 * Имя отображаемое при отладке
 */
CookieList.displayName = "CookieList";

export default CookieList;
