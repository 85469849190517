import React from "react";
import {
    Button,
    Card,
    CardHeader,
    Field,
    Input,
    Label,
    Subtitle2,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    useId,
    useToastController
} from "@fluentui/react-components";
import UseStyle from './ChangePasswordFormStyles';
import {useFormik} from "formik";
import {ChangePasswordDto} from "../../models/account";
import {useServices} from "../../hooks";
import {GetMessageByCode} from "../../utils";
import {defaultToastId} from "../../shared/constString";
import {ErrorCode} from "../../models/ErrorCode";
import {validate} from "./ChangePassword.validation";


const ChangePasswordForm: React.FC = () => {
    const styles = UseStyle();
    const oldPassword = "oldPassword";
    const newPassword = "newPassword";
    const confirmNewPassword = "confirmNewPassword";
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        },
        validate,
        onSubmit: async (values) => {
            await onChangePasswordSubmit(values);
        },
    });

    const onChangePasswordSubmit = async (values: ChangePasswordDto) => {
        try {
            await services.accountService.changePassword(values);
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>Password <b>changed</b> successfully!</ToastBody>
                </Toast>,
                {intent: "success"}
            );
        } catch (error: any) {
            const codeError = error.code as ErrorCode;
            if (codeError === ErrorCode.PasswordMismatch) {
                formik.errors.oldPassword = "Password invalid";
            }
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{GetMessageByCode(error.code)}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    }

    return (<>
        <Card className={styles.cardContainer}>
            <CardHeader
                header={<Subtitle2>Change password</Subtitle2>}>
            </CardHeader>
            <div>
                <form autoComplete="off" className={styles.form} onSubmit={formik.handleSubmit}>
                    <div>
                        <Label htmlFor={oldPassword}>Old password</Label>
                        <Field validationMessage={(formik.errors.oldPassword) ? formik.errors.oldPassword : null}>
                            <Input type="password"
                                   required
                                   id={oldPassword}
                                   size={"large"}
                                   onChange={formik.handleChange}
                                   value={formik.values.oldPassword}/>
                        </Field>
                    </div>

                    <div>
                        <Label htmlFor={newPassword}>New password</Label>
                        <Field validationMessage={(formik.errors.newPassword) ? formik.errors.newPassword : null}>
                            <Input type="password"
                                   id={newPassword}
                                   required
                                   size={"large"}
                                   onChange={formik.handleChange}
                                   value={formik.values.newPassword}/>
                        </Field>
                    </div>

                    <div>
                        <Label htmlFor={confirmNewPassword}>Confirm password</Label>
                        <Field
                            validationMessage={(formik.errors.confirmNewPassword) ? formik.errors.confirmNewPassword : null}>
                            <Input type="password"
                                   required
                                   id={confirmNewPassword}
                                   size={"large"}
                                   onChange={formik.handleChange}
                                   value={formik.values.confirmNewPassword}
                            />
                        </Field>
                    </div>
                    <div>
                        <Button size={"large"} appearance={"primary"} type={"submit"}>Change password</Button>
                    </div>
                </form>
            </div>

            <Toaster toasterId={toasterId}/>
        </Card>
    </>);
}

ChangePasswordForm.displayName = "ChangePasswordForm";
export default ChangePasswordForm;