export enum ErrorCode {
    /**
     * Неправильный пароль или логин
     */
    IncorrectLoginOrPassword = 1,

    /**
     * Ошибка обновления данных о подписке
     */
    SubscriptionInfoUpdateFailed = 2,

    /**
     * Пароли не совпадают
     */

    PasswordNotEqual = 3,

    /**
     * Не правильный пароль
     */
    PasswordMismatch = 4,

    /**
     * В пароле должны быть не только цифры
     */
    PasswordRequiresNonAlphanumeric = 5,

    /**
     * В пароле должны присутствовать символы нижнего регистра
     */
    PasswordRequiresLower = 6,

    /**
     * В пароле должны присутствовать символы верхнего регистра
     */
    PasswordRequiresUpper = 7,

    /**
     * кастомная ошибка с сервера
     */
    CustomErrorMessage = 8,

    /**
     * ошибка доступности билдера
     */
    BuilderIsUnavailable = 9,

    /**
     * Файл слишком большой
     */
    TooLargeFile = 10,

    /**
     * Пользователь не существует
     */
    UserDoesNotExist = 11,

    /**
     * Пароль слишком короткий
     */
    PasswordToShort = 12,

    /**
     * В имени пользователя должен быть знак @
     */
    AddAtToUsername = 13,

    /**
     * Ошибка получения данных
     */
    UnableGetData = 1000,

    /**
     * Ошибка сервера
     */
    InternalServerError = 500,

    /**
     * Не предвиденная ошибка
     */
    UnexpectedError = 99999999,


}