/**
 * Список ролей в системе
 */
export enum UserRole {
    /**
     * Администратор
     */
    AdminRole = 'Admin',

    /**
     * Обычный пользователь
     */
    UserRole = 'User',

    /**
     * Партнёр по трафику
     */
    PartnerRole = 'Traffer',
}