import React from "react";
import {ProgressBar} from "@fluentui/react-components";
import {useStore} from "../../hooks";
import useStyles from "./LoaderStyle";
import {observer} from "mobx-react-lite";

/**
 * Компонент для отображения процесса загрузки
 */
const Loader: React.FC = () => {
    const store = useStore();
    const styles = useStyles();
    return (<>{store.loaderStore.loading && <ProgressBar className={styles.loader} thickness={"large"}/>}</>);
}

/**
 * Имя отображаемое для отладки
 */
Loader.displayName = "Loader";

export default observer(Loader);