import {TableColumnSizingOptions} from "@fluentui/react-table";

/**
 * Настройки размеров столбцов
 */
export const columnSizingOptions: TableColumnSizingOptions = {
    'logId': {
        minWidth: 20,
        defaultWidth: 30,
    },
    'ip': {
        minWidth: 75,
        defaultWidth: 80,
    },
    'country': {
        minWidth: 55,
        defaultWidth: 60,
    },
    'city': {
        minWidth: 70,
        defaultWidth: 140,
    },
    'zipCode': {
        minWidth: 70,
        defaultWidth: 75,
    },
    'actions': {
        minWidth: 115,
        defaultWidth: 120,
    },
    'created': {
        minWidth: 140,
        defaultWidth: 150
    },
    'summary': {
        minWidth: 300,
        defaultWidth: 520,
    }
}