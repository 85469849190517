import React, { useState, useEffect } from 'react';
import { useFormik } from "formik";
import { CookiesRestoreDto } from "../../models/cookiesRestore";
import { defaultToastId } from "../../shared/constString";
import { validate } from "./CookiesRestore.validation";
import { GetMessageByCode } from "../../utils";

import {
    Button,
    Card,
    CardHeader,
    Field,
    Input,
    Label,
    Subtitle2,
    Toast,
    ToastBody,
    Toaster,
    ToastTitle,
    useId,
    useToastController,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent
} from "@fluentui/react-components";
import UseStyle from './CookiesRestoreFormStyles';
import { useServices } from '../../hooks';
import { ErrorCode } from '../../models/ErrorCode';

//компонент отображения формы восстановления кукисов

const CookiesRestoreForm: React.FC = () => {
    const styles = UseStyle();
    const toasterId = useId(defaultToastId);
    const restoreKey = "restoreKey";
    const proxyHost = "proxyHost";
    const socksUser = "socksUser";
    const socksPassword = "socksPassword";
    const { dispatchToast } = useToastController(toasterId);
    const [isOpen, setIsOpen] = useState(true);
    const handleClose = () => setIsOpen(false);
    const formik = useFormik({
        initialValues: {
            restoreKey: '',
            proxyHost: '',
            socksUser: '',
            socksPassword: ''
        },
        validate,
        onSubmit: async (values) => {
            await onRestoreCookiesSubmit(values);
        },
    });

    const services = useServices();

    /**
     * восстановление кукисов и вывод формы с восстановленными кукисами 
     */
    const onRestoreCookiesSubmit = async (values: CookiesRestoreDto) => {

        try {
            const response = services.cookiesRestoreService.cookiesRestore(values);
            //то как используется dispatchToast - это не правильно. нужно будет поправить
            dispatchToast(
                <Dialog open={isOpen}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>Cookies</DialogTitle>
                            <DialogContent>
                                {(await response).data}
                            </DialogContent>
                            <DialogActions>
                                <Button appearance="secondary" onClick={handleClose}>Close</Button>

                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            );
        } catch (error: any) {
            const codeError = error.code as ErrorCode;
            dispatchToast(
                <Toast style={{ minHeight: 50 + "px", minWidth: 200 + "px" }}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.messasge : error.code}</ToastBody>
                </Toast>,
                { intent: "error" }
            );
        }



    }

    return (<>
        <Card className={styles.cardContainer}>
            <CardHeader
                header={<Subtitle2>Restore cookies</Subtitle2>}>
            </CardHeader>
            <div>
                <form autoComplete="off" className={styles.form} onSubmit={formik.handleSubmit}>
                    <div>
                        <Label htmlFor={restoreKey}>Restore token</Label>
                        <Field validationMessage={(formik.errors.restoreKey) ? formik.errors.restoreKey : null}>
                            <Input
                                required
                                id={restoreKey}
                                size={"large"}
                                onChange={formik.handleChange}
                                value={formik.values.restoreKey} />
                        </Field>
                    </div>

                    <div>
                        <Label htmlFor={proxyHost}>Socks5 host</Label>
                        <Field validationMessage={(formik.errors.proxyHost) ? formik.errors.proxyHost : null}>
                            <Input
                                id={proxyHost}
                                required
                                size={"large"}
                                onChange={formik.handleChange}
                                value={formik.values.proxyHost} />
                        </Field>
                    </div>

                    <div>
                        <Label htmlFor={socksUser}>Socks5 login</Label>
                        <Field
                            validationMessage={(formik.errors.socksUser) ? formik.errors.socksUser : null}>
                            <Input
                                required
                                id={socksUser}
                                size={"large"}
                                onChange={formik.handleChange}
                                value={formik.values.socksUser}
                            />
                        </Field>
                    </div>

                    <div>
                        <Label htmlFor={socksPassword}>Socks5 password</Label>
                        <Field
                            validationMessage={(formik.errors.socksPassword) ? formik.errors.socksPassword : null}>
                            <Input
                                required
                                id={socksPassword}
                                size={"large"}
                                onChange={formik.handleChange}
                                value={formik.values.socksPassword}
                            />
                        </Field>
                    </div>

                    <div>
                        <Button size={"large"} appearance={"primary"} type={"submit"}>Restore cookies</Button>
                    </div>
                </form>
            </div>

            <Toaster toasterId={toasterId} />
        </Card>
    </>);
}


CookiesRestoreForm.displayName = "CookiesRestoreForm";
export default CookiesRestoreForm;