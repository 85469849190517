import React from "react";
import {Layout} from "../../containers/Layout";
import {LogList} from "../../components/LogsList";
import LogFilters from "../../components/LogsList/LogFilters/LogFilters";

/**
 * Страница отображения списка логово пользователя
 * @constructor
 */
const LogListPage: React.FC = () => {
    return (<>
        <Layout pageTittle={"Log list"}>
            <LogFilters/>
            <LogList/>
        </Layout>
    </>);
}
export default LogListPage;