import React from "react";
import {Card} from "@fluentui/react-components";
import {InfoButtonProps} from "./InfoButtonProps";
import {useNavigate} from "react-router-dom";

const InfoButton: React.FC<InfoButtonProps> = (props) => {
    const navigate = useNavigate();

    const onCardClick = () => {
        if (props.target) {
            console.log(props.target);
            navigate(props.target);
        }
    }

    return (
        <Card onClick={onCardClick}>
            <div>{props.title}</div>
            <div></div>
        </Card>);
}


InfoButton.displayName = "InfoButton";

export default InfoButton;