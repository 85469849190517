import {makeStyles, tokens} from "@fluentui/react-components";

export default makeStyles({
    loader: {
        position: "fixed",
        margin: 0,
        padding: 0,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 99999,
        boxShadow: "0 0 2px 0",
        backgroundColor: tokens.colorBrandStroke1
    },
    loader_progress_bar: {
        margin: 0,
        padding: 0,
        zIndex: 99998,
        boxShadow: "0 0 10px 0",
        height: "4px",
        backgroundColor: "rgb(233, 87, 33)",
        color: "rgb(233, 87, 33)",
        transitionProperty: "all",
        transitionTimingFunction: "ease-in-out",
        transitionDelay: "0s",
    },
});