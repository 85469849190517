import { Layout } from "../../containers/Layout";
import CookieList from "../../components/CookiesList";
import React from "react";

const CookiesPage: React.FC = () => {
  return (
    <>
      <Layout pageTittle="Cookies">
        <CookieList></CookieList>
      </Layout>
    </>
  );
};

CookiesPage.displayName = "CookiesPage";

export default CookiesPage;