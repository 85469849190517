import React, {useCallback, useEffect, useState} from 'react';
import {
    Badge,
    Button,
    Card,
    CardHeader,
    Label,
    Subtitle2,
    Text,
    Toast,
    ToastBody,
    ToastTitle,
    useId,
    useToastController
} from '@fluentui/react-components';
import UseStyle from './BuilderFormStyles';
import {useServices} from '../../hooks';
import {BuilderRequestViewDto, BuildRequestStatesDto} from '../../models/builder';
import {ErrorCode} from '../../models/ErrorCode';
import {defaultToastId} from "../../shared/constString";

// форма статуса билдера
const BuilderStatusForm: React.FC = () => {
    const styles = UseStyle();
    const [builderInfo, setBuilderInfo] = useState<BuilderRequestViewDto | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    const fetchBuilderInfo = useCallback(async () => {
        try {
            const response = await services.builderService.getBuilderInfo();
            setBuilderInfo(response.data);
        } catch (error: any) {
            dispatchToast(
                <Toast style={{ minHeight: 50 + "px", minWidth: 200 + "px" }}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.message : error.code}</ToastBody>
                </Toast>,
                { intent: "error" }
            );
        } finally {
            setLoading(false);
        }
    }, [services.builderService, dispatchToast]);

    /**
     * получение текущего статуса билдера
     */
    useEffect(() => {

        fetchBuilderInfo();

        // таймер обновления статуса
        const intervalId = setInterval(fetchBuilderInfo, 5000);

        return () => clearInterval(intervalId);
    }, [fetchBuilderInfo]);

    //событие кнопуки скачать
    const handleDownload = async (requestId: string, filename: string) => {
        try {
            const response = await services.builderService.downloadBuild(requestId);
            const blob = new Blob([response.data], {type: 'application/octet-stream'});
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.messasge : error.code}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    if (loading) {
        return <Text>Loading...</Text>;
    }

    if (error) {
        return <Text>{error}</Text>;
    }

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Subtitle2>Builder Status</Subtitle2>}/>
            {builderInfo && (
                <div>
                    <div style={{margin: '0.3em'}}>
                        <Label>Remaining Builds:</Label>
                        <Badge>{builderInfo.buildRemainsCount}</Badge>
                    </div>
                    {builderInfo.currentState && (
                        <>
                            <div style={{margin: '0.3em'}}>
                                <Label>Current State:</Label>
                                <Badge>{BuildRequestStatesDto[builderInfo.currentState.state]}</Badge>
                            </div>
                            {builderInfo.currentState.fileSize !== undefined && builderInfo.currentState.fileSize > 0 && (
                                <div style={{margin: '0.3em'}}>
                                    <Label>File Size:</Label>
                                    <Text>{builderInfo.currentState.fileSize} bytes</Text>
                                </div>
                            )}
                            {builderInfo.currentState.state === BuildRequestStatesDto.Done && (
                                <Button style={{margin: '0.3em'}}
                                        onClick={() => handleDownload(builderInfo.currentState.requestId, builderInfo.currentState.requestId + '.zip')}>
                                    Download
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </Card>
    );
};

/**
 * Имя отображаемое при отладке
 */
BuilderStatusForm.displayName = 'BuilderStatusForm';

export default BuilderStatusForm;