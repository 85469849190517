import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardHeader,
    Input,
    Option,
    Select,
    Subtitle2,
    Toast,
    ToastBody,
    ToastTitle,
    useId,
    useToastController
} from '@fluentui/react-components';
import {useServices} from '../../hooks';
import UseStyle from './BuilderFormStyles';
import {BuilderOptionsDto, UserDto} from '../../models/builder';
import {ErrorCode} from '../../models/ErrorCode';
import {defaultToastId} from "../../shared/constString";

/**
 * Форма создания запроса на генерацию билда
 * @constructor
 */
const BuilderForm: React.FC = () => {
    const styles = UseStyle();
    const [partnerId, setPartnerId] = useState('');
    const [buildTag, setBuildTag] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [referrals, setReferrals] = useState<UserDto[]>([]);
    const services = useServices();
    const toasterId = useId(defaultToastId);
    const {dispatchToast} = useToastController(toasterId);

    /**
     * Получение списка рефералов
     */
    const fetchReferrals = useCallback(async () => {
        try {
            const result = await services.builderService.getRefList();
            setReferrals(result.data || []);
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: "50px", minWidth: "200px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.message}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    }, [services.builderService, dispatchToast]);


    useEffect(() => {
        (async () => {
            await fetchReferrals();
        })();
    }, [services.builderService, dispatchToast, fetchReferrals]);

    /**
     * отправка запроса на генерацию билда
     */
    const handleBuild = async () => {
        try {
            const body: BuilderOptionsDto = {partnerId: partnerId || '', buildTag: buildTag};
            await services.builderService.newRequest(body);
            setError(null);
        } catch (error: any) {
            dispatchToast(
                <Toast style={{minHeight: 50 + "px", minWidth: 200 + "px"}}>
                    <ToastTitle>Error!</ToastTitle>
                    <ToastBody>{error.code === ErrorCode.CustomErrorMessage ? error.message : error.code}</ToastBody>
                </Toast>,
                {intent: "error"}
            );
        }
    };

    return (
        <Card className={styles.cardContainer}>
            <CardHeader header={<Subtitle2>Create New Build</Subtitle2>}/>
            <form className={styles.form}>
                {referrals.length > 0 && (
                    <div key={"form"}>
                        <Select
                            value={partnerId}
                            onChange={(event) => setPartnerId(event.target.value)}
                        >
                            <Option value="">Select Referral</Option>
                            {referrals.map((referral) => (
                                <Option key={referral.Id} value={referral.Id}>
                                    {referral.UserName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                )}
                <div>
                    <Input
                        placeholder="Enter build tag"
                        value={buildTag}
                        onChange={(event) => setBuildTag(event.target.value)}
                    />
                </div>
                {error && <div className={styles.error}>{error}</div>}
                <Button onClick={handleBuild}>Build</Button>
            </form>
        </Card>
    );
};

BuilderForm.displayName = "BuilderForm";

export default BuilderForm;
